import _ from 'lodash';

import { Language, RenderingType, SubsectionVariant, Answers, Timezone } from '@breathelife/types';

import { NodeIdAnswersResolver } from './answersResolver';
import { getAllSubsections } from './questionnaire';
import { RenderingQuestionnaireGenerator, getQuestionnaireNodes } from './renderingTransforms';
import { QuestionnaireDefinition } from './structure';
import { makeFieldValidationSchemas } from './validations';
import { Localized } from './locale';

const INFORMATIONAL_SUBSECTION_VARIANTS = [
  SubsectionVariant.productSummary,
  SubsectionVariant.priceUpdateWarning,
  SubsectionVariant.summary,
  SubsectionVariant.submission,
];

export function calculateProgress(
  questionnaire: Localized<QuestionnaireDefinition>,
  answers: Answers,
  answersResolver: NodeIdAnswersResolver,
  progressOffset = 0,
  timezone: Timezone,
  isCompleted?: boolean,
  landingStepId?: string,
): number {
  let progress: number;

  if (isCompleted) {
    return 100;
  }

  const renderingQuestionnaireGenerator = new RenderingQuestionnaireGenerator({
    questionnaire,
    questionnaireNodes: getQuestionnaireNodes(questionnaire),
    shouldValidateAllAnswers: false,
    answers,
    answersResolver,
    text: () => '',
    language: Language.en,
    renderingType: RenderingType.web,
    timezone,
    applicationContext: {},
    fieldValidationSchemas: makeFieldValidationSchemas(() => '', timezone),
  });
  const renderingQuestionnaire = renderingQuestionnaireGenerator.get();
  const allSubsections = getAllSubsections(renderingQuestionnaire);
  const allVisibleSubsections = allSubsections.filter((step) => step.visible);

  const allSubsectionsContributingToProgress = allVisibleSubsections.filter(
    (subsection) => !INFORMATIONAL_SUBSECTION_VARIANTS.includes(subsection.variant ?? SubsectionVariant.form),
  );
  const completedSubsections = allSubsectionsContributingToProgress.filter((step) => step.completed);

  if (!progressOffset) {
    progress = Math.round((completedSubsections.length / allSubsectionsContributingToProgress.length) * 100);
  } else {
    const isLandingStepIdCompleted = completedSubsections.some((step) => step.id === landingStepId);
    const completedSubsectionsBeforeApplicationFill = !isLandingStepIdCompleted ? completedSubsections.length : 0;

    const completedSubsectionsAfterApplicationFill =
      completedSubsections.length - completedSubsectionsBeforeApplicationFill;
    const allSubsectionsAfterApplicationFill =
      allSubsectionsContributingToProgress.length - completedSubsectionsBeforeApplicationFill;

    progress = Math.round(
      (completedSubsectionsAfterApplicationFill / allSubsectionsAfterApplicationFill) * (100 - progressOffset) +
        progressOffset,
    );
  }

  if (!_.isFinite(progress)) progress = 0;
  return progress;
}
