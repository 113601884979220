import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import BaseBuilder from './BaseBuilder';

export default abstract class NodeBuilder<
  TBaseBuilderNodeType,
  TNodeType extends TBaseBuilderNodeType = TBaseBuilderNodeType,
> extends BaseBuilder<TBaseBuilderNodeType> {
  protected node: Partial<TNodeType>;

  protected constructor(nodeSeed: Partial<TNodeType>) {
    super();
    this.node = { ...nodeSeed, blueprintId: uuid() };
  }

  public abstract validate(node: TNodeType): node is TNodeType;

  public build(): TNodeType {
    try {
      this.validate(this.node as TNodeType);
      return _.cloneDeep(this.node) as TNodeType;
    } catch (error: any) {
      throw Error(`Error when building: ${error.message}`);
    }
  }
}
