import { Result, failure, success } from '@breathelife/result';
import { Answers, BlueprintId, InstanceIndex, NodeId, SurrogateId } from '@breathelife/types';
import { NodeIdAnswersResolver, BlueprintIdAnswersResolver, MissingAnswerPath } from '../answersResolver';

type Label = string;

export type AnswersPerInstanceAndLabel<T extends string, A> = Record<
  SurrogateId,
  {
    repeatedIndex: InstanceIndex;
    answersByLabel: Partial<Record<T, A>>;
  }
>;

export type AnswersPerInstanceAndNodeId<T extends string, A> = Record<
  SurrogateId,
  {
    repeatedIndex: InstanceIndex;
    answersByNodeId: Partial<Record<T, A>>;
  }
>;

export type AnswersPerInstanceAndId<T extends string, A> = Record<
  SurrogateId,
  {
    repeatedIndex: InstanceIndex;
    answersById: Partial<Record<T, A>>;
  }
>;

export class AnswerNotFound extends Error {
  readonly tag = 'AnswerNotFound';
}

export class RepetitionCountNotFound extends Error {
  readonly tag = 'RepetitionCountNotFound';
}

export class RepeatedAnswersNotFound extends Error {
  readonly tag = 'RepeatedAnswersNotFound';
}

export class CorrespondingIdentifiersNotFound extends Error {
  readonly tag = 'CorrespondingIdentifiersNotFound';
}

export class UnknownIdentifier extends Error {
  readonly tag = 'UnknownIdentifier';
}

export interface AnswersUtilities {
  getAnswer(
    id: Label,
    scope?: Record<Label, InstanceIndex>,
  ): Result<AnswerNotFound | UnknownIdentifier | CorrespondingIdentifiersNotFound, unknown>;
  getRepetitionCount(
    collectionId: Label,
    scope: Record<Label, InstanceIndex>,
  ): Result<CorrespondingIdentifiersNotFound | UnknownIdentifier | RepetitionCountNotFound, number>;
  getRepeatedAnswers<T extends Label>(
    collectionId: Label,
    ids: T[],
    scope?: Record<Label, InstanceIndex>,
  ): Result<
    CorrespondingIdentifiersNotFound | UnknownIdentifier | RepeatedAnswersNotFound,
    AnswersPerInstanceAndLabel<T, unknown>
  >;
  usingNodeIds: {
    getAnswer(id: NodeId, scope: Record<NodeId, InstanceIndex>): any | undefined;
    getRepetitionCount(collectionId: NodeId, scope: Record<NodeId, InstanceIndex>): number | undefined;
    getCollection(id: NodeId, scope: Record<NodeId, InstanceIndex>): any[] | undefined;
    getRepeatedAnswers<T extends string>(
      collectionId: NodeId,
      ids: T[],
      scope?: Record<NodeId, InstanceIndex>,
    ): AnswersPerInstanceAndNodeId<T, any> | undefined;
  };
  usingBlueprintIds: {
    getAnswer(
      id: BlueprintId,
      scope?: Record<BlueprintId, InstanceIndex>,
    ): Result<AnswerNotFound | UnknownIdentifier, unknown>;
    getRepetitionCount(
      collectionId: BlueprintId,
      scope: Record<BlueprintId, InstanceIndex>,
    ): Result<RepetitionCountNotFound | UnknownIdentifier, number>;
    getRepeatedAnswers<T extends BlueprintId>(
      collectionId: BlueprintId,
      ids: T[],
      scope?: Record<BlueprintId, InstanceIndex>,
    ): Result<RepeatedAnswersNotFound | UnknownIdentifier, AnswersPerInstanceAndId<T, unknown>>;
  };
}

function hasBeenWrittenTo(answers: Answers): boolean {
  return !!answers && Object.keys(answers).length > 0;
}

function buildContextFromLabels(
  labelsMap: Map<string, { nodeId?: string; blueprintId: string }>,
  context: Record<Label, InstanceIndex>,
  convertTo: 'BlueprintId' | 'NodeId',
): Result<CorrespondingIdentifiersNotFound, Record<string, number>> {
  const translatedContext: Record<string, InstanceIndex> = {};

  for (const key of Object.keys(context)) {
    const parentIdentifiers = labelsMap.get(key);

    if (!parentIdentifiers) {
      return failure(
        new CorrespondingIdentifiersNotFound('Corresponding identifiers for the label are missing.', { cause: key }),
      );
    }

    if (convertTo === 'BlueprintId') {
      translatedContext[parentIdentifiers.blueprintId] = context[key];
    } else {
      const nodeId = parentIdentifiers.nodeId;
      if (!nodeId) {
        return failure(
          new CorrespondingIdentifiersNotFound(`Label "${key}" does not have a corresponding nodeId.`, { cause: key }),
        );
      }
      translatedContext[nodeId] = context[key];
    }
  }
  return success(translatedContext);
}

// TODO: When the Big PR is merged, replace answersV1 + answersV2 by VersionedAnswers.
export const getAnswersUtilities = (
  answersV1: Answers,
  answersV2: Answers,
  answersResolverV1: NodeIdAnswersResolver,
  answersResolverV2: BlueprintIdAnswersResolver,
  questionnaireNodeLookup: Record<string, { nodeId?: string; blueprintId: string }>,
): AnswersUtilities => {
  const labelsMap = new Map<string, { nodeId?: string; blueprintId: string }>();

  Object.keys(questionnaireNodeLookup).forEach((key) => {
    labelsMap.set(key, questionnaireNodeLookup[key]);
  });

  return {
    getAnswer: (id: Label, context: Record<Label, InstanceIndex> = {}) => {
      const identifiers = labelsMap.get(id);
      if (!identifiers) {
        return failure(
          new CorrespondingIdentifiersNotFound('Corresponding identifiers for the label are missing.', { cause: id }),
        );
      }

      if (hasBeenWrittenTo(answersV2)) {
        const blueprintIdContextResult = buildContextFromLabels(labelsMap, context, 'BlueprintId');
        if (blueprintIdContextResult.success === false) {
          return blueprintIdContextResult;
        }

        return getAnswerForBlueprintIds(answersResolverV2, answersV2)(
          identifiers.blueprintId,
          blueprintIdContextResult.value,
        );
      } else {
        if (!identifiers.nodeId) {
          return failure(
            new CorrespondingIdentifiersNotFound('Corresponding nodeId for the label is missing.', { cause: id }),
          );
        }

        const nodeIdContextResult = buildContextFromLabels(labelsMap, context, 'NodeId');
        if (nodeIdContextResult.success === false) {
          return nodeIdContextResult;
        }

        try {
          const answerV1 = answersResolverV1.getAnswer(answersV1, identifiers.nodeId, nodeIdContextResult.value);

          if (!answerV1) {
            return failure(new AnswerNotFound('Answer not found for the given label.', { cause: id }));
          }

          return success(answerV1);
        } catch (e: unknown) {
          if (e instanceof MissingAnswerPath) {
            return failure(
              new UnknownIdentifier(`The identifier "${e.cause}" is not a known identifier for this questionnaire.`, {
                cause: e.cause,
              }),
            );
          }
          throw e;
        }
      }
    },
    getRepetitionCount: (
      collectionId: Label,
      scope: Record<Label, InstanceIndex> = {},
    ): Result<CorrespondingIdentifiersNotFound | UnknownIdentifier | RepetitionCountNotFound, number> => {
      const identifiers = labelsMap.get(collectionId);

      if (!identifiers) {
        return failure(
          new CorrespondingIdentifiersNotFound('Corresponding identifiers for the label are missing.', {
            cause: collectionId,
          }),
        );
      }

      if (hasBeenWrittenTo(answersV2)) {
        const blueprintIdContextResult = buildContextFromLabels(labelsMap, scope, 'BlueprintId');
        if (blueprintIdContextResult.success === false) {
          return failure(blueprintIdContextResult.error);
        }

        return getRepetitionCountForBlueprintIds(answersResolverV2, answersV2)(
          identifiers.blueprintId,
          blueprintIdContextResult.value,
        );
      } else {
        if (!identifiers.nodeId) {
          return failure(
            new CorrespondingIdentifiersNotFound('Corresponding nodeId for the label is missing.', {
              cause: collectionId,
            }),
          );
        }

        const nodeIdContextResult = buildContextFromLabels(labelsMap, scope, 'NodeId');
        if (nodeIdContextResult.success === false) {
          return failure(nodeIdContextResult.error);
        }

        try {
          const countV1 = answersResolverV1.getRepetitionCount(
            answersV1,
            identifiers.nodeId,
            nodeIdContextResult.value,
          );

          return success(countV1 || 0);
        } catch (e: unknown) {
          if (e instanceof MissingAnswerPath) {
            return failure(
              new UnknownIdentifier(`The identifier "${e.cause}" is not a known identifier for this questionnaire.`, {
                cause: e.cause,
              }),
            );
          }
          throw e;
        }
      }
    },
    getRepeatedAnswers: <T extends Label>(
      collectionId: Label,
      labels: T[],
      scope: Record<Label, InstanceIndex> = {},
    ): Result<
      CorrespondingIdentifiersNotFound | UnknownIdentifier | RepeatedAnswersNotFound,
      AnswersPerInstanceAndLabel<T, unknown>
    > => {
      const identifiers = labelsMap.get(collectionId);

      if (!identifiers) {
        return failure(
          new CorrespondingIdentifiersNotFound('Corresponding identifiers for the label are missing.', {
            cause: collectionId,
          }),
        );
      }

      if (hasBeenWrittenTo(answersV2)) {
        const blueprintIdsFromLabels = [];
        for (let i = 0; i < labels.length; i++) {
          const labelIdentifiers = labelsMap.get(labels[i]);
          if (!labelIdentifiers) {
            return failure(
              new CorrespondingIdentifiersNotFound('Corresponding identifiers for the label are missing.', {
                cause: labels[i],
              }),
            );
          }
          blueprintIdsFromLabels.push(labelIdentifiers.blueprintId);
        }

        let blueprintIdContextResult;
        if (scope) {
          blueprintIdContextResult = buildContextFromLabels(labelsMap, scope, 'BlueprintId');
          if (blueprintIdContextResult.success === false) {
            return failure(blueprintIdContextResult.error);
          }
        }

        const repeatedAnswersResult = getRepeatedAnswersForBlueprintIds(answersResolverV2, answersV2)(
          identifiers.blueprintId,
          blueprintIdsFromLabels,
          blueprintIdContextResult?.value || {},
        );

        if (repeatedAnswersResult.success === false) {
          return failure(repeatedAnswersResult.error);
        }
        const repeatedAnswers = repeatedAnswersResult.value;

        // Translate back to the label.
        const bucket: AnswersPerInstanceAndLabel<T, unknown> = {};
        for (let i = 0; i < Object.keys(repeatedAnswers).length; i++) {
          const surrogateId = Object.keys(repeatedAnswers)[i];
          const answersByLabel: Partial<Record<T, unknown>> = {};
          for (let l = 0; l < labels.length; l++) {
            const labelIdentifiers = labelsMap.get(labels[l]);
            if (!labelIdentifiers) {
              return failure(
                new CorrespondingIdentifiersNotFound('Corresponding identifiers for the label are missing.', {
                  cause: labels[l],
                }),
              );
            }
            answersByLabel[labels[l]] = repeatedAnswers[surrogateId].answersById[labelIdentifiers.blueprintId];
          }
          bucket[surrogateId] = {
            answersByLabel,
            repeatedIndex: i,
          };
        }

        return success(bucket);
      } else {
        if (!identifiers.nodeId) {
          return failure(
            new CorrespondingIdentifiersNotFound('Corresponding nodeId for the label is missing.', {
              cause: collectionId,
            }),
          );
        }

        const nodeIdsFromLabels = [];
        for (let i = 0; i < labels.length; i++) {
          const labelIdentifiers = labelsMap.get(labels[i]);
          if (!labelIdentifiers) {
            return failure(
              new CorrespondingIdentifiersNotFound('Corresponding identifiers for the label are missing.', {
                cause: labels[i],
              }),
            );
          }
          if (!labelIdentifiers.nodeId) {
            return failure(
              new CorrespondingIdentifiersNotFound('Corresponding nodeId for the label are missing.', {
                cause: labels[i],
              }),
            );
          }
          nodeIdsFromLabels.push(labelIdentifiers.nodeId);
        }

        const nodeIdContextResult = buildContextFromLabels(labelsMap, scope, 'NodeId');
        if (nodeIdContextResult.success === false) {
          return failure(nodeIdContextResult.error);
        }

        try {
          const value = answersResolverV1.getRepeatedAnswers(
            answersV1,
            identifiers.nodeId,
            nodeIdsFromLabels,
            nodeIdContextResult.value,
          );

          if (!value) {
            return failure(
              new RepeatedAnswersNotFound('Could not find the collection for the given context', {
                cause: nodeIdContextResult.value,
              }),
            );
          }

          const repeatedAnswers = value || {};

          // Translate back to the label.
          const bucket: AnswersPerInstanceAndLabel<T, unknown> = {};
          for (let i = 0; i < Object.keys(repeatedAnswers).length; i++) {
            const answersByLabel: Partial<Record<T, unknown>> = {};
            for (let l = 0; l < labels.length; l++) {
              const labelIdentifiers = labelsMap.get(labels[l]);
              if (!labelIdentifiers) {
                return failure(
                  new CorrespondingIdentifiersNotFound('Corresponding identifiers for the label are missing.', {
                    cause: labels[l],
                  }),
                );
              }
              answersByLabel[labels[l]] = repeatedAnswers[i].answersByNodeId[labelIdentifiers.blueprintId];
            }
            bucket[i] = {
              answersByLabel,
              repeatedIndex: i,
            };
          }

          return success(bucket);
        } catch (e: unknown) {
          if (e instanceof MissingAnswerPath) {
            return failure(
              new UnknownIdentifier(`The identifier "${e.cause}" is not a known identifier for this questionnaire.`, {
                cause: e.cause,
              }),
            );
          }
          throw e;
        }
      }
    },
    usingNodeIds: {
      getAnswer: (id: NodeId, context: Record<NodeId, InstanceIndex>): any | undefined => {
        return answersResolverV1.getAnswer(answersV1, id, context);
      },
      getRepetitionCount: (id: NodeId, context: Record<NodeId, InstanceIndex>): number | undefined => {
        return answersResolverV1.getRepetitionCount(answersV1, id, context);
      },
      getCollection: (id: NodeId, context: Record<NodeId, InstanceIndex>): any[] | undefined => {
        return answersResolverV1.getCollection(answersV1, id, context);
      },
      getRepeatedAnswers: <T extends string>(
        collectionNodeId: NodeId,
        ids: T[],
        context: Record<NodeId, InstanceIndex> = {},
      ): AnswersPerInstanceAndNodeId<T, unknown> | undefined => {
        return answersResolverV1.getRepeatedAnswers(answersV1, collectionNodeId, ids, context);
      },
    },
    usingBlueprintIds: {
      // This section is more aligned with the regular sdk functions than the "usingNodeIds" because its gonna last.
      getAnswer: getAnswerForBlueprintIds(answersResolverV2, answersV2),
      getRepetitionCount: getRepetitionCountForBlueprintIds(answersResolverV2, answersV2),
      getRepeatedAnswers: getRepeatedAnswersForBlueprintIds(answersResolverV2, answersV2),
    },
  };
};

function getAnswerForBlueprintIds(answersResolverV2: BlueprintIdAnswersResolver, answersV2: Answers) {
  return (
    id: BlueprintId,
    context: Record<BlueprintId, InstanceIndex> = {},
  ): Result<AnswerNotFound | UnknownIdentifier, unknown> => {
    try {
      const answer = answersResolverV2.getAnswer(answersV2, id, context);

      if (!answer) {
        return failure(new AnswerNotFound('Answer not found for the given label.', { cause: id }));
      }

      return success(answer);
    } catch (e: unknown) {
      if (e instanceof MissingAnswerPath) {
        return failure(
          new UnknownIdentifier(`The identifier "${e.cause}" is not a known identifier for this questionnaire.`, {
            cause: e.cause,
          }),
        );
      }
      throw e;
    }
  };
}

function getRepetitionCountForBlueprintIds(answersResolverV2: BlueprintIdAnswersResolver, answersV2: Answers) {
  return (
    collectionId: BlueprintId,
    context: Record<BlueprintId, InstanceIndex>,
  ): Result<RepetitionCountNotFound | UnknownIdentifier, number> => {
    try {
      const repetitionCount = answersResolverV2.getRepetitionCount(answersV2, collectionId, context);

      if (!repetitionCount) {
        return failure(
          new RepetitionCountNotFound('Repetition count not found for the given label.', { cause: collectionId }),
        );
      }

      return success(repetitionCount);
    } catch (e: unknown) {
      if (e instanceof MissingAnswerPath) {
        return failure(
          new UnknownIdentifier(`The identifier "${e.cause}" is not a known identifier for this questionnaire.`, {
            cause: e.cause,
          }),
        );
      }
      throw e;
    }
  };
}

function getRepeatedAnswersForBlueprintIds<T extends BlueprintId>(
  answersResolverV2: BlueprintIdAnswersResolver,
  answersV2: Answers,
) {
  return (
    collectionId: BlueprintId,
    ids: T[],
    context?: Record<BlueprintId, InstanceIndex>,
  ): Result<RepeatedAnswersNotFound | UnknownIdentifier, AnswersPerInstanceAndId<T, unknown>> => {
    try {
      const repeatedAnswers = answersResolverV2.getRepeatedAnswers(answersV2, collectionId, ids, context || {});

      if (!repeatedAnswers) {
        return failure(
          new RepeatedAnswersNotFound('Repeated answers not found for the given label.', { cause: collectionId }),
        );
      }

      const bucket: AnswersPerInstanceAndId<T, unknown> = {};
      for (let i = 0; i < Object.keys(repeatedAnswers).length; i++) {
        const surrogateId = Object.keys(repeatedAnswers)[i];
        bucket[surrogateId] = {
          answersById: repeatedAnswers[surrogateId].answersByNodeId,
          repeatedIndex: i,
        };
      }

      return success(bucket);
    } catch (e: unknown) {
      if (e instanceof MissingAnswerPath) {
        return failure(
          new UnknownIdentifier(`The identifier "${e.cause}" is not a known identifier for this questionnaire.`, {
            cause: e.cause,
          }),
        );
      }
      throw e;
    }
  };
}
