import {
  evaluateConditions as conditionEngineEvaluateConditions,
  evaluateQuery as conditionEngineEvaluateQuery,
} from '@breathelife/condition-engine';
import { Conditions, OperatorResult, Query, InstanceScope, Answers, Timezone } from '@breathelife/types';

import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from './answersResolver';

export function evaluateConditions(
  conditions: Conditions,
  answers: Answers,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap = new Map(),
  repeatedInstanceIdentifiers: InstanceScope,
  timezone: Timezone,
): boolean {
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  return conditionEngineEvaluateConditions(conditions, answers, answersResolver, repeatedInstanceIdentifiers, timezone)
    .isValid;
}

export function evaluateQuery(
  query: Query,
  answers: Answers,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap = new Map(),
  repeatedInstanceIdentifiers: InstanceScope,
  timezone: Timezone,
): OperatorResult {
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
  return conditionEngineEvaluateQuery(query, answers, answersResolver, repeatedInstanceIdentifiers, timezone);
}
