import { InstanceScope, Answers } from '@breathelife/types';

import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from '../answersResolver';

export function selectAnswersForNodeIds(
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  answers: Answers,
  relevantNodeIds: string[],
  scope?: InstanceScope,
): Answers {
  const answersInScope = {};
  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);

  relevantNodeIds.forEach((nodeId) => {
    const answerPath = nodeIdToAnswerPathMap.get(nodeId);
    const answerValue = answersResolver.getAnswer(answers, nodeId, scope, undefined, answerPath?.isCollection);

    if (typeof answerValue !== 'undefined') {
      answersResolver.setAnswer(answerValue, answersInScope, nodeId, scope);
    }
  });

  return answersInScope;
}
