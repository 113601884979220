import _ from 'lodash';

import { RenderingField, RenderingSubsection } from '../renderingTransforms';
import { Field, Subsection } from '../structure';

export function getAllFieldsInSubsection(subsection: RenderingSubsection): RenderingField[];
export function getAllFieldsInSubsection(subsection: Subsection): Field[];

export function getAllFieldsInSubsection(subsection: Subsection | RenderingSubsection): Field[] | RenderingField[] {
  return _.flatMap(subsection.questions, 'fields');
}
