import { SerializedNodeIdToAnswerPathMap, serializeNodeIdToAnswerPathMap } from '../utils/apiUtils';
import { NodeIdToAnswerPathMap } from './makeNodeIdToAnswerPathMap';

export type ReferencePath = { fromValueAt: string };

export type Path = string | ReferencePath;

export function isReferencePath(path: Path): path is ReferencePath {
  return !!(path as ReferencePath).fromValueAt;
}

export class AnswerPath {
  public path: Path;
  public nodeId?: string;
  public isCollection: boolean;
  public parent?: AnswerPath;

  constructor({
    path,
    nodeId = undefined,
    parent = undefined,
    isCollection = false,
  }: {
    path: Path;
    nodeId?: string;
    parent?: AnswerPath;
    isCollection?: boolean;
  }) {
    this.path = path;
    this.nodeId = nodeId;
    this.isCollection = isCollection;
    this.parent = parent;
  }

  /** Count collection AnswerPath's in [parent, grandparent, ...] */
  public static getAncestorCollectionCount(answerPath: AnswerPath): number {
    let currentAnswerPath: AnswerPath | undefined = answerPath?.parent;
    let ancestorCollectionCount = 0;

    while (currentAnswerPath) {
      if (currentAnswerPath.isCollection) {
        ancestorCollectionCount += 1;
      }

      currentAnswerPath = currentAnswerPath.parent;
    }

    return ancestorCollectionCount;
  }

  public static toIdentifiableString(answerPath: AnswerPath, divider: string = '.'): string {
    let str = isReferencePath(answerPath.path) ? `<${answerPath.path.fromValueAt}>` : answerPath.path;

    if (answerPath.parent) {
      const parentString = AnswerPath.toIdentifiableString(answerPath.parent, divider);
      str = `${parentString}${divider}${str}`;
    }

    return str;
  }

  public static getAncestorCollectionAnswerPath(answerPath: AnswerPath): AnswerPath | null {
    if (!answerPath.parent) {
      return null;
    } else if (answerPath.parent.isCollection) {
      return answerPath.parent;
    }

    // Has a parent but parent is not a collection, continue search up the tree.
    return this.getAncestorCollectionAnswerPath(answerPath.parent);
  }

  // To find the nodeId of a given collection answer path,
  // we can match against the JSON path described by the tree.
  public static getIdentifiableStringToCollectionNodeIdMap(
    nodeIdToAnswerPath: SerializedNodeIdToAnswerPathMap,
  ): Record<string, string> {
    return nodeIdToAnswerPath.reduce(
      (idStringToNodeId, [nodeId, answerPath]) => {
        if (answerPath.isCollection) {
          const identifiableString = AnswerPath.toIdentifiableString(answerPath);
          idStringToNodeId[identifiableString] = nodeId;
        }

        return idStringToNodeId;
      },
      {} as Record<string, string>,
    );
  }

  public static getCollectionNodeId(answerPath: AnswerPath, nodeIdAnswerPathMap: NodeIdToAnswerPathMap): string {
    const serializedNodeIdToAnswerPathMap = serializeNodeIdToAnswerPathMap(nodeIdAnswerPathMap);
    const collectionNodeIdMap = this.getIdentifiableStringToCollectionNodeIdMap(serializedNodeIdToAnswerPathMap);
    const collectionIdentifiableString = this.toIdentifiableString(answerPath);

    return collectionNodeIdMap[collectionIdentifiableString];
  }
}
