import _ from 'lodash';

import { InstanceScope } from '@breathelife/types';

import { appendRepeatableInstancesToId } from '../renderingTransforms/RepeatableExpansion';
import {
  EvaluatedVisibilityNode,
  RenderingField,
  RenderingQuestion,
  RenderingQuestionnaire,
  RenderingSection,
  RenderingSectionGroup,
  RenderingSubsection,
  RepeatableMetadata,
} from './RenderingQuestionnaire';

// TODO @MultiInsured This goes elsewhere -- but where?
export function isRenderingQuestionnaireComplete(questionnaire: RenderingQuestionnaire): boolean {
  return _.every(questionnaire, ['completed', true]);
}

// TODO @MultiInsured -- Move these to a questionnaireQueries module
function getAllQuestions(questionnaire: RenderingQuestionnaire): RenderingQuestion[] {
  const sections: RenderingSection[] = _.flatMap(questionnaire, 'sections');
  const subsections: RenderingSubsection[] = _.flatMap(sections, 'subsections');
  return _.flatMap(subsections, 'questions');
}

function getAllFields(questionnaire: RenderingQuestionnaire): RenderingField[] {
  const questions: RenderingQuestion[] = getAllQuestions(questionnaire);
  return _.flatMap(questions, 'fields');
}

export function getSectionGroupById(
  sectionGroups: RenderingQuestionnaire,
  id: string,
): RenderingSectionGroup | undefined {
  return _.find(sectionGroups, (sectionGroup) => sectionGroup.id === id);
}

export function getSectionById(sectionGroup: RenderingSectionGroup, id: string): RenderingSection | undefined {
  return _.find(sectionGroup.sections, (section) => section.id === id);
}

export function findField(
  questionnaire: RenderingQuestionnaire,
  fieldId: string,
  repeatedIdentifiers?: InstanceScope,
): RenderingField | undefined {
  const allFields: RenderingField[] = getAllFields(questionnaire);

  const targetId = appendRepeatableInstancesToId(fieldId, repeatedIdentifiers ?? {});
  return allFields.find((field: RenderingField) => field.id === targetId);
}

export type TransitionNode = {
  id: string;
  [key: string]: unknown;
};

export type TransitionNodeWithMetadata = TransitionNode & {
  metadata?: RepeatableMetadata | { repeatedInstanceIdentifierContext: InstanceScope };
};

export type TransitionNodeWithVisibility = TransitionNode & EvaluatedVisibilityNode;

// TODO @MultiInsured -- Get rid of this typing -- Each worker in the transformation pipeline should define their in/out based on `Questionnaire` and `RenderingQuestionnaire`
// Types for a Questionnaire that's transitioning to a RenderingQuestionnaire and has a mix of properties from both
// TODO write actual types instead of `any` once the RenderingQuestionnaire type is more stable
export type TransitionQuestionnaire = TransitionSectionGroup[];
export type TransitionSectionGroup = any;
export type TransitionSection = any;
export type TransitionSubsection = any;
export type TransitionQuestion = any;
export type TransitionField = any;
export type TransitionOption = any;
