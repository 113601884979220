import { v4 as uuid } from 'uuid';

import {
  Answers,
  ApplicationContext,
  InstanceScope,
  DEFAULT_TIMEZONE,
  FieldTypes,
  IAnswerResolver,
  Language,
  RenderingType,
  SubsectionVariant,
  Timezone,
} from '@breathelife/types';
import _ from 'lodash';
import {
  RenderingField,
  RenderingFieldOption,
  RenderingOptionField,
  RenderingQuestion,
  RenderingQuestionnaire,
  RenderingSection,
  RenderingSectionGroup,
  RenderingSubsection,
} from './RenderingQuestionnaire';
import {
  DynamicOptionField,
  Field,
  Question,
  QuestionnaireDefinition,
  RepeatableOptions,
  RepeatableOptionsWithLimits,
  RepeatableQuestion,
  RepeatableQuestionnaireNode,
  RepeatableSectionGroup,
  Section,
  SectionGroup,
  SelectOption,
  Subsection,
  isRepeatableOptionsBasedOnCollection,
  isDynamicOptionField,
  OptionField,
} from '../structure';
import { Localized, TextGetter } from '../locale';
import { FieldValidationSchemas, Validations } from '../validations';
import { buildValidationErrorMessage, evaluateRules, evaluateVisibility } from '../nodeEvaluation';
import { appendRepeatableInstancesToId, formatRepeatableQuestionTitle } from './RepeatableExpansion';
import { hasBeenAnswered } from '../answers';
import { getDynamicOptions } from './populateDynamicOptions';
import {
  FieldWithValue,
  getAppendToKeyValue,
  getAppendToKeyValueForFieldWithDefaultIf,
} from './QuestionnaireTransforms';
import { getInitialFieldValue } from '../questionnaire';
import { isRenderingRepeatedQuestion } from './Structure';

type BlueprintId = string;
type Pointer =
  | RenderingSectionGroup
  | Localized<SectionGroup>
  | RenderingSection
  | Localized<Section>
  | RenderingSubsection
  | Localized<Subsection>
  | RenderingQuestion
  | Localized<Question>
  | RenderingField
  | Localized<Field>;

export function getQuestionnaireNodes(
  questionnaire: Localized<QuestionnaireDefinition>,
): Map<BlueprintId, QuestionnaireNodeItem> {
  const map = new Map<BlueprintId, QuestionnaireNodeItem>();

  for (const sectionGroup of questionnaire) {
    map.set(sectionGroup.blueprintId, { pointer: sectionGroup, parent: null });
    for (const section of sectionGroup.sections) {
      map.set(section.blueprintId, { pointer: section, parent: sectionGroup.blueprintId });
      for (const subsection of section.subsections) {
        map.set(subsection.blueprintId, { pointer: subsection, parent: section.blueprintId });
        for (const question of subsection.questions) {
          map.set(question.blueprintId, { pointer: question, parent: subsection.blueprintId });
          for (const field of question.fields) {
            map.set(field.blueprintId, { pointer: field, parent: question.blueprintId });
          }
        }
      }
    }
  }

  return map;
}

function partialClone(questionnaire: Localized<QuestionnaireDefinition>): Localized<QuestionnaireDefinition> {
  const newQuestionnaire = [];
  for (const sectionGroup of questionnaire) {
    const newSectionGroup = { ...sectionGroup };

    const newSections: Localized<Section[]> = [];
    for (const section of newSectionGroup.sections) {
      const newSection = { ...section };

      const newSubsections: Localized<Subsection[]> = [];
      for (const subsection of newSection.subsections) {
        const newSubsection = { ...subsection };

        const newQuestions: Localized<Question[]> = [];
        for (const question of subsection.questions) {
          const newQuestion = { ...question };

          const newFields: Localized<Field[]> = [];
          for (const field of question.fields) {
            const newField = { ...field };

            if ((field as Localized<OptionField>).options) {
              const newOptions: Localized<SelectOption[]> = [];

              for (const option of (field as Localized<OptionField>).options) {
                const newOption = { ...option };
                newOptions.push(newOption);
              }

              (newField as Localized<OptionField>).options = newOptions;
            }
            newFields.push(newField);
          }
          newQuestion.fields = newFields;
          newQuestions.push(newQuestion);
        }

        newSubsection.questions = newQuestions;
        newSubsections.push(newSubsection);
      }

      newSection.subsections = newSubsections;
      newSections.push(newSection);
    }
    newSectionGroup.sections = newSections;
    newQuestionnaire.push(newSectionGroup);
  }

  return newQuestionnaire;
}

export type QuestionnaireNodeItem = {
  pointer:
    | Localized<SectionGroup>
    | Localized<Section>
    | Localized<Subsection>
    | Localized<Question>
    | Localized<Field>;
  parent: BlueprintId | null;
};

type Env = {
  questionnaireNodes: Map<BlueprintId, QuestionnaireNodeItem>;
  answers: Answers;
  answersResolver: IAnswerResolver;
  renderingType: RenderingType;
  timezone: Timezone;
  applicationContext: ApplicationContext;
  language: Language;
  allFieldsCompleted: boolean;
  text: TextGetter;
  shouldValidateAllAnswers: boolean;
  fieldSchemas: FieldValidationSchemas;
  isLoadingFields: boolean;
};

type Dependencies = {
  questionnaire: Localized<QuestionnaireDefinition>;
  questionnaireNodes: Map<BlueprintId, QuestionnaireNodeItem>;
  answersResolver: IAnswerResolver;
  answers: Answers;
  applicationContext: ApplicationContext;
  language: Language;
  shouldValidateAllAnswers: boolean;
  fieldValidationSchemas: FieldValidationSchemas;
  text?: TextGetter;
  allFieldsCompleted?: boolean;
  renderingType?: RenderingType;
  timezone?: Timezone;
  isLoadingFields?: boolean;
};

export class RenderingQuestionnaireGenerator {
  private env: Env;
  private renderingQuestionnaire: RenderingQuestionnaire;

  constructor(deps: Dependencies, cloningStrategy: 'CacheSafe' | 'Fast' = 'CacheSafe') {
    let clonedQuestionnaire: Localized<QuestionnaireDefinition>;
    if (cloningStrategy === 'Fast') {
      clonedQuestionnaire = partialClone(deps.questionnaire);
    } else {
      clonedQuestionnaire = _.cloneDeep(deps.questionnaire);
    }

    this.env = {
      questionnaireNodes: deps.questionnaireNodes,
      answersResolver: deps.answersResolver,
      answers: deps.answers,
      renderingType: deps.renderingType || RenderingType.web,
      timezone: deps.timezone || DEFAULT_TIMEZONE,
      applicationContext: deps.applicationContext,
      language: deps.language,
      allFieldsCompleted: deps.allFieldsCompleted || false,
      text: deps.text || ((e) => e),
      shouldValidateAllAnswers: deps.shouldValidateAllAnswers,
      fieldSchemas: deps.fieldValidationSchemas,
      isLoadingFields: deps.isLoadingFields || false, // This is an old hack to disable the greencheck marks (completed) while the questionnaire is loading.
    };

    this.renderingQuestionnaire = mainLoop(clonedQuestionnaire, this.env);
  }

  get(): RenderingQuestionnaire {
    return this.renderingQuestionnaire;
  }
}

function mainLoop(questionnaire: Localized<QuestionnaireDefinition>, env: Env): RenderingQuestionnaire {
  const scopeRoot: InstanceScope = {};

  const sectionGroups = questionnaire;
  const [expandedSectionGroups, sectionGroupTracking] = expandChildren(sectionGroups, scopeRoot, env);

  let indexSectionGroup = 0;
  let previousSectionGroupId: string | null = null;
  for (const sectionGroup of expandedSectionGroups) {
    if (previousSectionGroupId && sectionGroup.blueprintId !== previousSectionGroupId) {
      indexSectionGroup = 0;
    }
    const scopeSectionGroup: InstanceScope = { ...scopeRoot };
    if (sectionGroup.options?.repeatable && (sectionGroup as Localized<RepeatableSectionGroup>).nodeId) {
      scopeSectionGroup[(sectionGroup as Localized<RepeatableSectionGroup>).nodeId] = indexSectionGroup;
    }

    updateSectionGroup(
      sectionGroup,
      scopeSectionGroup,
      indexSectionGroup,
      sectionGroupTracking[sectionGroup.blueprintId] || 1,
      env,
    );

    if ((sectionGroup as any).visible) {
      const oneSection = { isNotCompleted: false };

      const sections = sectionGroup.sections;
      const [expandedSections] = expandChildren(sections, scopeSectionGroup, env);

      for (const section of expandedSections) {
        const scopeSection = scopeSectionGroup;

        updateSection(section, scopeSection, env);

        if ((section as any).visible) {
          const oneSubsection = { isNotCompleted: false };

          const subsections = section.subsections;
          const [expandedSubsections] = expandChildren(subsections, scopeSection, env);

          for (const subsection of expandedSubsections) {
            const scopeSubsection = scopeSection;

            updateSubsection(subsection, scopeSubsection, env);

            if ((subsection as any).visible) {
              const oneQuestion = { isNotCompleted: false };

              const questions = subsection.questions;
              const [expandedQuestions, questionTracking] = expandChildren(questions, scopeSubsection, env);

              let indexQuestion = 0;
              let previousQuestionId: string | null = null;
              for (const question of expandedQuestions) {
                if (previousQuestionId && question.blueprintId !== previousQuestionId) {
                  indexQuestion = 0;
                }

                const scopeQuestion = { ...scopeSubsection };

                if (question.options?.repeatable && (question as RepeatableQuestion).nodeId) {
                  scopeQuestion[(question as RepeatableQuestion).nodeId] = indexQuestion;
                }

                const total = questionTracking[question.blueprintId];
                updateQuestion(question, scopeQuestion, indexQuestion, total, env);

                if ((question as any).visible) {
                  const oneField = { isVisible: false, isInvalid: false };

                  const fields = question.fields;
                  const [expandedFields] = expandChildren(fields, scopeQuestion, env);
                  for (const field of expandedFields) {
                    updateField(field, scopeQuestion, oneField, env);

                    if ((field as any).options && (field as any).visible === true) {
                      for (const option of (field as any).options) {
                        updateOption(option, scopeQuestion, env);
                      }
                    }
                  }
                  question.fields = expandedFields;

                  if (question.fields?.length > 0 && oneField.isVisible === false) {
                    (question as any).visible = false;
                  }

                  (question as any).completed = env.isLoadingFields ? false : oneField.isInvalid === false;

                  if ((question as any).completed === false) {
                    oneQuestion.isNotCompleted = true;
                  }
                }

                indexQuestion++;
                previousQuestionId = question.blueprintId;
              }
              subsection.questions = expandedQuestions;

              (subsection as any).completed = env.isLoadingFields ? false : oneQuestion.isNotCompleted === false;

              if ((subsection as any).completed === false) {
                oneSubsection.isNotCompleted = true;
              }
            }
          }
          section.subsections = expandedSubsections;

          (section as any).completed = env.isLoadingFields ? false : oneSubsection.isNotCompleted === false;

          if ((section as any).completed === false) {
            oneSection.isNotCompleted = true;
          }
        }
      }
      sectionGroup.sections = expandedSections;

      (sectionGroup as any).completed = env.isLoadingFields ? false : oneSection.isNotCompleted === false;
    }

    indexSectionGroup++;
    previousSectionGroupId = sectionGroup.blueprintId;
  }

  return expandedSectionGroups as any as RenderingQuestionnaire;
}

export function expandChildren<T extends Pointer>(
  original: T[] | Localized<QuestionnaireDefinition>,
  scope: InstanceScope,
  env: Env,
): [T[], Record<BlueprintId, number>] {
  const tracking: Record<BlueprintId, number> = {};
  const totalWantedByBlueprintId: Record<BlueprintId, number> = {};

  const newChildren = [];

  for (let elementIndex = 0; elementIndex < original.length; ++elementIndex) {
    const currentElement = original[elementIndex];
    tracking[currentElement.blueprintId] = (tracking[currentElement.blueprintId] || 0) + 1;
    newChildren.push(currentElement); // Push/reuse the original to save memory/performance.

    const options: RepeatableOptions | undefined = (currentElement as any as SectionGroup).options;
    if (options && options.repeatable) {
      // Calculate total wanted if its the first time we find that node.
      let totalWanted = totalWantedByBlueprintId[currentElement.blueprintId];
      if (!totalWanted) {
        totalWanted = getTotalWanted(currentElement, scope, env);
        totalWantedByBlueprintId[currentElement.blueprintId] = totalWanted;
      }

      const nextIndex = elementIndex + 1;

      // If we are at the end of the array or about to switch another blueprint ID.
      if (nextIndex >= original.length || original[nextIndex].blueprintId !== currentElement.blueprintId) {
        const existingSiblings = tracking[currentElement.blueprintId];

        for (let i = existingSiblings; i < totalWanted; ++i) {
          const definition = env.questionnaireNodes.get(currentElement.blueprintId);
          if (!definition) {
            throw new Error(`Can find the blueprintId "${currentElement.blueprintId}" in the questionnaire nodes map.`);
          }
          const newSibling = JSON.parse(JSON.stringify(definition.pointer)); // Algorithm to generate the sub-tree.
          newChildren.push(newSibling); // Clone the element or take it from the blueprintMap in the diagram (the later for final solution)
          tracking[currentElement.blueprintId] = tracking[currentElement.blueprintId] + 1;
        }
      }
    }
  }
  return [newChildren, tracking];
}

function getTotalWanted(pointer: Pointer, scope: InstanceScope, env: Env): number {
  const currentElement = pointer as any as RepeatableQuestionnaireNode;

  if ((currentElement.options as RepeatableOptionsWithLimits).minRepetitions) {
    return (
      env.answersResolver.getRepetitionCount(
        env.answers,
        (currentElement as any as RepeatableQuestionnaireNode).nodeId,
        scope,
      ) || 1
    );
  }

  // TODO: Total Wanted based on another collection.

  // totalWanted = howManyBased on XYZ collection.
  return 1;
}

function updateRepetitionMetadataWithAdditionalScope(
  pointer: Pointer,
  scope: InstanceScope,
  index: number,
  total: number,
  env: Env,
): void {
  const node: RepeatableQuestionnaireNode = pointer as any as RepeatableQuestionnaireNode;

  const surrogateId = surrogateIdFor(node.nodeId, scope, env);

  (pointer as any).surrogateId = surrogateId;
  (pointer as any).metadata = {};
  (pointer as any).metadata.repetitionIndex = index;
  (pointer as any).metadata.repetitionCount = total;
  (pointer as any).metadata.parentId = node.id;
  pointer.id = `${pointer.id}.${index}`;
  (pointer as any).metadata.repeatedInstanceIdentifierContext = scope; // Maybe removed ? Its also done in updateRepetitionMetadataWithParentData
}

function updateSubsection(subsection: Localized<Subsection>, scope: InstanceScope, env: Env): void {
  (subsection as any).metadata = { repeatedInstanceIdentifierContext: scope };

  (subsection as any).visible = evaluateVisibility(
    subsection,
    env.renderingType,
    env.answersResolver,
    env.answers,
    scope,
    env.timezone,
    subsection.visibleIf,
  );

  if (!subsection.variant) {
    subsection.variant = SubsectionVariant.form;
  }
}

function updateSection(section: Localized<Section>, scope: InstanceScope, env: Env): void {
  (section as any).metadata = { repeatedInstanceIdentifierContext: scope };

  (section as any).visible = evaluateVisibility(
    section,
    env.renderingType,
    env.answersResolver,
    env.answers,
    scope,
    env.timezone,
    section.visibleIf,
  );
}

function updateField(
  field: Localized<Field>,
  scope: InstanceScope,
  oneField: { isVisible: boolean; isInvalid: boolean },
  env: Env,
): void {
  (field as any).metadata = { repeatedInstanceIdentifierContext: scope };
  if (Object.keys(scope).length > 0) {
    field.id = appendRepeatableInstancesToId(field.id, scope);
  }

  if ((field as any).options) {
    const f = field as RenderingOptionField;
    for (const option of f.options) {
      (option as any).metadata = {
        repeatedInstanceIdentifierContext: f.metadata.repeatedInstanceIdentifierContext,
      };
    }
  }

  (field as any).visible = evaluateVisibility(
    field,
    env.renderingType,
    env.answersResolver,
    env.answers,
    scope,
    env.timezone,
    field.visibleIf,
  );

  if (!(field as any).visible) {
    return;
  }

  oneField.isVisible = true;

  populateDynamicOptions(field, scope, env);

  populateApplicationContext(field, scope, env);

  setFieldValues(field, scope, env);

  setFieldSchemaValidations(field, scope, env);

  evaluateValidityConditions(field, scope, env);

  if ((field as any).valid === false) {
    oneField.isInvalid = true;
  }

  if (env.allFieldsCompleted) {
    field.disabled = true;
  }
}

function updateOption(option: SelectOption, scope: InstanceScope, env: Env): void {
  (option as any).visible = evaluateVisibility(
    option,
    env.renderingType,
    env.answersResolver,
    env.answers,
    (option as any).metadata?.repeatedInstanceIdentifierContext || scope,
    env.timezone,
    option.visibleIf,
  );
}

function setFieldSchemaValidations(field: Localized<Field>, scope: InstanceScope, env: Env): void {
  if (env.allFieldsCompleted) {
    return;
  }

  // Fields explicitly marked optional:false are always required
  const isRequired =
    field.optional === false || (!field.optional && !field.disabled && field.type !== FieldTypes.information);

  const fieldValidationType: Validations = field.validation.type;
  const fieldSchema = isRequired
    ? env.fieldSchemas.required[fieldValidationType]
    : env.fieldSchemas.optional[fieldValidationType];

  const fieldWithEvaluatedValidation = field as any;
  try {
    fieldSchema.validateSync((field as any).value);
    fieldWithEvaluatedValidation.valid = true;
  } catch (error: any) {
    fieldWithEvaluatedValidation.valid = false;
    if (env.shouldValidateAllAnswers || hasBeenAnswered((field as any).value)) {
      fieldWithEvaluatedValidation.validationError = { message: error.message };
    }
  }
}

function evaluateValidityConditions(field: Localized<Field>, scope: InstanceScope, env: Env): void {
  const { invalidRules, validationData } = evaluateRules(
    field.validIf ?? [],
    env.answers,
    env.answersResolver,
    scope,
    env.timezone,
    field?.validation?.type,
  );

  const fieldAnswer = env.answersResolver.getAnswer(env.answers, field.nodeId, scope);

  const isValid = invalidRules.length === 0;
  (field as any).valid = (field as any).valid && isValid;
  (field as any).validationData = validationData;

  const shouldSetValidationMessage = env.shouldValidateAllAnswers || hasBeenAnswered(fieldAnswer);
  if (!isValid && shouldSetValidationMessage && !(field as any).validationError) {
    const firstBrokenRule = invalidRules[0];
    (field as any).validationError = { message: buildValidationErrorMessage(firstBrokenRule) };
  }
}

function populateDynamicOptions(field: Localized<Field>, scope: InstanceScope, env: Env): void {
  if (isDynamicOptionField(field)) {
    const node = field as DynamicOptionField;

    const dynamicOptions = getDynamicOptions(node.dynamicOptions, env.answers, scope, env.answersResolver);

    const transitionDynamicOptions: SelectOption[] = dynamicOptions.map(
      (option: any) =>
        ({
          ...option,
          // TODO: When fr/en can possibly differ, localize this. (copied from the visitor dunno why its english only) (Maybe -> env.text(env.language))
          text: option.text.en,
        }) as any as SelectOption,
    );

    node.options = [...transitionDynamicOptions, ...(node.options || [])];
  }
}

function setFieldValues(field: Localized<Field>, scope: InstanceScope, env: Env): void {
  const answer = env.answersResolver.getAnswer(env.answers, field.nodeId, scope);
  const fieldWithValue = field as unknown as FieldWithValue;
  fieldWithValue.value = typeof answer === 'undefined' ? getInitialFieldValue(field as RenderingField) : answer;

  if (field.defaultIf !== undefined) {
    (field as any).appendToKeyValue = getAppendToKeyValueForFieldWithDefaultIf(field, env.answers, env.answersResolver);
  }

  if (field.relatesTo !== undefined) {
    const relatesToValue = env.answersResolver.getAnswer(env.answers, field.relatesTo, scope);

    if (typeof relatesToValue !== 'undefined') {
      const appendToKeyValue = fieldWithValue.appendToKeyValue ?? '';
      fieldWithValue.appendToKeyValue = getAppendToKeyValue(appendToKeyValue, relatesToValue);
    }
  }
}

function populateApplicationContext(field: Localized<Field>, scope: InstanceScope, env: Env): void {
  const node = field as any;
  if (node.optionsFromApplicationContext) {
    const { tag, labelKey, valuePath } = node.optionsFromApplicationContext;

    const applicationContextData = env.applicationContext[tag];

    if (!applicationContextData) {
      return;
    }

    if (!Array.isArray(applicationContextData)) {
      return;
    }

    const renderingFieldOptions: RenderingFieldOption[] = applicationContextData.map((acd) => {
      return {
        id: _.get(acd, valuePath, ''),
        text: _.get(acd, labelKey[env.language], ''),
        disabled: node.disabled,
        iconName: node.iconName,
        info: node.info,
        metadata: scope,
        title: node.title,
        visible: (node as any).visible || false, // The default should not happen.
      };
    });

    node.options = renderingFieldOptions;
  }
}

function updateSectionGroup(
  sectionGroup: Localized<SectionGroup>,
  scope: InstanceScope,
  index: number,
  repetitionCount: number,
  env: Env,
): void {
  if ((sectionGroup as any).options?.repeatable) {
    updateRepetitionMetadataWithAdditionalScope(sectionGroup, scope, index, repetitionCount, env);
  } else {
    (sectionGroup as any).metadata = { repeatedInstanceIdentifierContext: scope };
  }

  (sectionGroup as any).visible = evaluateVisibility(
    sectionGroup,
    env.renderingType,
    env.answersResolver,
    env.answers,
    scope,
    env.timezone,
    sectionGroup.visibleIf,
  );
}

function updateQuestion(
  question: Localized<Question>,
  scope: InstanceScope,
  index: number,
  repetitionCount: number,
  env: Env,
): void {
  if ((question as any).options?.repeatable) {
    updateRepetitionMetadataWithAdditionalScope(question, scope, index, repetitionCount, env);
    const expandedQuestion = question as any;

    const repetitions = repetitionCount;

    if (isRepeatableOptionsBasedOnCollection(expandedQuestion.options)) {
      // nosemgrep: insecure-object-assign
      Object.assign(expandedQuestion, {
        showRemoveQuestionButton: false,
        showAddQuestionButton: false,
      });
      return;
    }

    const hasReachedMinimumRepetitions: boolean = repetitions === expandedQuestion.options.minRepetitions;
    const isLastRepetition: boolean = index === repetitions - 1;
    const hasRepetitionsLeft: boolean = index < expandedQuestion.options.maxRepetitions - 1;

    // nosemgrep: insecure-object-assign
    Object.assign(expandedQuestion, {
      title: formatRepeatableQuestionTitle(expandedQuestion.title, index),
      showRemoveQuestionButton: !hasReachedMinimumRepetitions,
      showAddQuestionButton: isLastRepetition && hasRepetitionsLeft,
    });
  } else {
    (question as any).metadata = { repeatedInstanceIdentifierContext: scope };
  }

  (question as any).visible = evaluateVisibility(
    question,
    env.renderingType,
    env.answersResolver,
    env.answers,
    scope,
    env.timezone,
    question.visibleIf,
  );

  if (env.allFieldsCompleted && isRenderingRepeatedQuestion(question as any)) {
    (question as any).showRemoveQuestionButton = false;
    (question as any).showAddQuestionButton = false;
  }
}

function surrogateIdFor(nodeId: string, repeatedInstanceIdentifierContext: InstanceScope, env: Env): string {
  const existingSurrogateId = env.answersResolver.getAnswer(
    env.answers,
    nodeId,
    repeatedInstanceIdentifierContext,
  )?.surrogateId;

  return existingSurrogateId || uuid();
}
