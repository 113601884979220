import { ApplicationMode } from '@breathelife/types';

import QuestionnaireVisitor from '../QuestionnaireVisitor';
import { BaseNode, isField, Question, QuestionnaireDefinition } from '../structure';
import { Localized } from '../locale';

class FilterNodesByApplicationModeVisitor extends QuestionnaireVisitor {
  private readonly applicationModes: ApplicationMode[];

  constructor(applicationModes: ApplicationMode[]) {
    super();
    this.applicationModes = applicationModes;
  }

  public visitQuestion(question: Localized<Question>): void {
    question.fields = this.filterNodesByApplicationMode(question.fields);
    super.visitQuestion(question);
  }

  private filterNodesByApplicationMode<T extends Localized<BaseNode>>(nodes: T[]): T[] {
    return nodes.filter((node) => this.nodeMatchesApplicationMode(node));
  }

  private nodeMatchesApplicationMode(baseNode: Localized<BaseNode>): boolean {
    if (!isField(baseNode)) {
      return true;
    }

    if (!baseNode.applicationModes?.length) {
      return true;
    }

    return baseNode.applicationModes.some((nodeMode) => this.applicationModes.includes(nodeMode));
  }
}

export function filterNodesByApplicationMode(
  questionnaire: Localized<QuestionnaireDefinition>,
  applicationModes: ApplicationMode[],
): Localized<QuestionnaireDefinition> {
  if (!applicationModes.length) {
    return questionnaire;
  }

  const visitor = new FilterNodesByApplicationModeVisitor(applicationModes);
  const { questionnaire: filteredQuestionnaire } = visitor.visitQuestionnaire(questionnaire);

  return filteredQuestionnaire;
}
