import _ from 'lodash';

type Frame = {
  og: any;
  up: any;
  dirty: boolean;
  visited: boolean;
  nodeToUse: any;
  key: string | null;
  children: Frame[];
  parent: Frame | null;
  next: Frame | null;
};

class FramePool {
  private pool: Array<Frame> = [];
  quantity: number;

  constructor(initialQuantity: number) {
    this.quantity = initialQuantity;
    for (let i = 0; i < this.quantity; i++) {
      this.pool.push(createDefaultFrame());
    }
  }

  get(): Frame {
    if (this.quantity > 0) {
      const frame = this.pool[--this.quantity];
      this.pool.pop();
      return frame;
    }
    return createDefaultFrame();
  }

  release(frame: Frame): void {
    this.pool.push(frame);
    this.quantity++;
  }
}

function createDefaultFrame(): Frame {
  return {
    og: null,
    up: null,
    dirty: false,
    visited: false,
    nodeToUse: null,
    children: [],
    parent: null,
    key: null,
    next: null,
  };
}

const keysThatNeverChangeForSure = ['visibleIf', 'validIf', 'effects'];

export const shareQuestionnaireReferences = (a: any, b: any): any => {
  // The pool is an optimization to reuse the maximum amount of object
  // because object creation is expensive and by traversing the beneva tree we have to
  // approximately 180 000 objects. But we never use more than ~450 at the same time.
  // When we do the backpropagation we release the children nodes back to the pool.That means
  // that when we go back down another path we just reuse existing object.
  // When reusing an existing dirty reference that mean you have to explicitly set
  // all properties to the object to make sure the previous state of that node is not there anymore.
  // We don't clean node before adding them to the pool and that saves time since we know the caller
  // put its data on it anyway.
  // [!] This pool allows us to do the merge at 16kb instead of 440kb which is a game changer for speed.
  const pool = new FramePool(750);

  let current: Frame | null = pool.get();
  current.og = a;
  current.up = b;
  current.dirty = false;
  current.visited = false;
  current.nodeToUse = b;
  current.children = [];
  current.parent = null;
  current.key = null;
  current.next = null;

  let topNode: Frame | null = null;

  while (current !== null) {
    const ogType = typeof current.og;
    const upType = typeof current.up;

    if (ogType === 'object' && upType === 'object') {
      // Checking if it's a property that will never change.
      // Still not sure this gives us a perf boost but at least its not slower.
      if (!!current.key && keysThatNeverChangeForSure.includes(current.key)) {
        current.visited = true;
        current = current.next;
        continue;
      }
      // We are on a branch (arrays and objects)
      if (current.up.length >= 0 && current.og.length >= 0) {
        if (current.visited === false) {
          let next: Frame = current; //The last child next pointer goes to the parent.

          if (current.og.length !== current.up.length) {
            current.dirty = true;
          }

          // To keep order in the array we create last frame first so we can link it as the next on the one before it.
          // That reverse order saves an iteration to plug the next after the frames have been created.
          for (let i: number = current.up.length - 1; i >= 0; i--) {
            const frame = pool.get();
            frame.og = current.og[i];
            frame.up = current.up[i];

            //The following if do reconciliation when the array size has changed.
            //It tries to find the right references even if their index has moved.
            if (frame.up.surrogateId) {
              if (!frame.og || frame.up.surrogateId !== frame.og.surrogateId) {
                current.dirty = true;
                frame.og = current.og.find((e: any) => e.surrogateId === frame.up.surrogateId);
              }
            }

            // If the node does not have a surrogateId,
            // we verify that the node at the same index has the same blueprintId,
            // otherwise we search for the node in og that could have move that has the same blueprintId.
            else {
              if (!frame.og || frame.up.blueprintId !== frame.og.blueprintId) {
                current.dirty = true;
                frame.og = current.og.find((e: any) => e.blueprintId === frame.up.blueprintId);
              }
            }
            frame.children = [];
            frame.visited = false;
            frame.dirty = false;
            frame.nodeToUse = current.og[i];
            frame.parent = current;
            frame.key = null;
            frame.next = next;

            current.children.push(frame);
            next = frame;
          }

          current.visited = true;
          current = next;
          continue;
        } else {
          // Check which index needs to be updated in the array
          // rewiring. We decide if the current node should use the OG or UP node.
          // If it has changed, the node will carry UP, if its the same we want to use OG.
          if (current.dirty) {
            if (current.parent) {
              current.parent.dirty = true;
            }
            const childrenAmount = current.children.length - 1;
            const updatedChildren = [];
            for (let c = childrenAmount; c >= 0; c--) {
              const child = current.children[c];
              updatedChildren.push(child.nodeToUse);
              pool.release(child);
            }
            current.nodeToUse = updatedChildren;
          } else {
            current.nodeToUse = current.og;
            const childrenAmount = current.children.length - 1;
            for (let c = childrenAmount; c >= 0; c--) {
              pool.release(current.children[c]);
            }
          }

          topNode = current;
          // Next is the sibling in the tree most of the time. Sometimes its the parent.
          // Parent < ----------- next --------------- |
          //    |                                      |
          // Sibling - next -> Sibling - next -> Sibling
          current = current.next;
          continue;
        }
      } else {
        if (current.visited === false) {
          let next: Frame = current; //The last child next pointer goes to the parent.

          const keys: string[] = _.keys(current.up);
          const ogKeys: string[] = _.keys(current.og);

          if (keys.length !== ogKeys.length) {
            current.dirty = true;
          }

          const length = keys.length;

          if (length > 0) {
            // Order is not important for objects
            for (let i = 0; i < length; i++) {
              const key = keys[i];
              const fOg = current.og?.[key];
              const frame = pool.get();
              frame.og = fOg;
              frame.up = current.up?.[key];
              frame.visited = false;
              frame.children = [];
              frame.dirty = false;
              frame.nodeToUse = fOg;
              frame.key = key;
              frame.parent = current;
              frame.next = next;

              current.children.push(frame);
              next = frame;
            }
          }

          current.visited = true;
          current = next;
          continue;
        } else {
          // Check which key needs to be updated in the object
          // REWIRE
          if (current.dirty) {
            if (current.parent) {
              current.parent.dirty = true;
            }
            current.nodeToUse = {};
            for (const child of current.children) {
              current.nodeToUse[child.key as any] = child.nodeToUse;
              pool.release(child);
            }
          } else {
            current.nodeToUse = current.og;
            for (const child of current.children) {
              pool.release(child);
            }
          }

          topNode = current;
          current = current.next;
          continue;
        }
      }
    }

    // Default case, when either og or up is a primitive.
    if (current.og !== current.up) {
      current.dirty = true;
      current.nodeToUse = current.up;
      if (current.parent) {
        current.parent.dirty = true;
      }
    }

    current.visited = true;

    topNode = current;
    current = current.next;
    continue;
  }

  return topNode?.nodeToUse;
};
