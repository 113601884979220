import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { Answers, QuestionnaireBlueprintCopyableOption, NodeInstance } from '@breathelife/types';

import { NodeIdAnswersResolver } from '../answersResolver';
import { ExpandedRepetitionsVisitor } from '../expandedContext/ExpandedRepetitionsVisitor';
import {
  isRepeatableOptionsBasedOnCollection,
  QuestionnaireDefinition,
  RepeatableQuestion,
  RepeatableSectionGroup,
  Field,
} from '../structure';
import { Localized } from '../locale';

export class CopyAnswersVisitor extends ExpandedRepetitionsVisitor {
  private copyableOptions: QuestionnaireBlueprintCopyableOption[];
  private nodeIdsWithCollectionIdentifiers: NodeInstance[];
  private nodeIdsSetAsCopyable: string[];

  constructor(
    answersResolver: NodeIdAnswersResolver,
    answers: Answers,
    copyableOptions: QuestionnaireBlueprintCopyableOption[],
  ) {
    super(answersResolver, answers);
    this.copyableOptions = copyableOptions;
    this.nodeIdsWithCollectionIdentifiers = [];
    this.nodeIdsSetAsCopyable = [];
  }

  public visitQuestionnaire(questionnaire: Localized<QuestionnaireDefinition>): void {
    super.visitQuestionnaire(questionnaire);
    this.filterCopyableAnswers();
  }

  public getDefaultAnswers(): Answers {
    return this.answers;
  }

  protected visitRepeatedSectionGroup(sectionGroup: Localized<RepeatableSectionGroup>): void {
    if (!sectionGroup.readOnly) {
      this.overrideDefaultSurrogateIds(sectionGroup.nodeId);
      super.visitRepeatedSectionGroup(sectionGroup);
    }
  }

  protected visitRepeatedQuestion(question: Localized<RepeatableQuestion>, repetitionIndex: number): void {
    if (!question.readOnly) {
      if (isRepeatableOptionsBasedOnCollection(question.options)) {
        this.overrideDefaultSurrogateIds(question.nodeId, question.options.expandToCollectionLength, repetitionIndex);
      } else {
        this.overrideDefaultSurrogateIds(question.nodeId);
      }

      super.visitRepeatedQuestion(question, repetitionIndex);
    }
  }

  protected visitField(field: Localized<Field>): void {
    super.visitField(field);

    // If field is set to copyable, add to copyable node id array if not already included
    if (
      field.copyable &&
      this.copyableOptions.includes(field.copyable) &&
      !this.nodeIdsSetAsCopyable.includes(field.nodeId)
    ) {
      this.nodeIdsSetAsCopyable.push(field.nodeId);
    }

    // Add node id for each field to all node ids array if not already included
    if (
      this.nodeIdsWithCollectionIdentifiers.filter(
        (nodeIdIdentifier: NodeInstance) =>
          field.nodeId === nodeIdIdentifier.id && this.repeatedInstanceIdentifiers() === nodeIdIdentifier.scope,
      )
    ) {
      this.nodeIdsWithCollectionIdentifiers.push({
        id: field.nodeId,
        scope: this.repeatedInstanceIdentifiers(),
      });
    }
  }

  private overrideDefaultSurrogateIds(nodeId: string, collectionNodeId?: string, repetitionIndex?: number): void {
    const repeatedInstanceIdentifiers = this.repeatedInstanceIdentifiers();

    if (typeof repeatedInstanceIdentifiers[nodeId] === 'undefined') {
      // If no index is provided we cannot create a surrogateId for a collection item.
      return;
    }

    let identifiedAnswerItem = this.answersResolver.getAnswer(this.answers, nodeId, repeatedInstanceIdentifiers);

    if (typeof identifiedAnswerItem === 'undefined') {
      identifiedAnswerItem = {};
      this.answersResolver.setAnswer(identifiedAnswerItem, this.answers, nodeId, repeatedInstanceIdentifiers);
    }

    if (repeatedInstanceIdentifiers && collectionNodeId && repetitionIndex !== undefined) {
      //If we have an index and a collection NodeId, use the surrogateId from the same index in the collection.
      const correspondingItemInAnotherCollectionItem = this.answersResolver.getAnswer(this.answers, collectionNodeId, {
        [collectionNodeId]: repetitionIndex,
      });
      identifiedAnswerItem.surrogateId = correspondingItemInAnotherCollectionItem?.surrogateId || uuid();
    } else {
      const surrogateId = uuid();
      identifiedAnswerItem.surrogateId = surrogateId;
    }
  }

  // Unsets all answers that don't link to a node id that is set as copyable
  private filterCopyableAnswers(): void {
    const collectionArray: string[] = [];
    const nodeIdsToUnset = this.nodeIdsWithCollectionIdentifiers.filter(
      (value) => !this.nodeIdsSetAsCopyable.includes(value.id),
    );
    nodeIdsToUnset.forEach((value) => {
      this.answersResolver.unsetAnswer(this.answers, value.id, value.scope);
      for (const nodeId in value.scope) {
        if (!collectionArray.includes(nodeId)) {
          collectionArray.push(nodeId);
        }
      }
    });
    collectionArray.forEach((nodeId) => {
      const collection = this.answersResolver.getCollection(this.answers, nodeId);
      if (collection) {
        collection.forEach((value, index) => {
          const remainingAnswers = Object.keys(value).filter((key) => key !== 'surrogateId');
          if (!remainingAnswers.length && index > 0) {
            this.answersResolver.unsetAnswer(this.answers, nodeId, {
              [nodeId]: index,
            });
          }
        });
        this.answersResolver.removeUndefinedAnswersFromCollection(nodeId, this.answers);
      }
    });
  }
}
export function copyAnswers(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: NodeIdAnswersResolver,
  existingAnswers: Answers,
  copyableOptions: QuestionnaireBlueprintCopyableOption[],
): Answers {
  const updatedAnswers = _.cloneDeep(existingAnswers);

  const copyAnswersVisitor = new CopyAnswersVisitor(answersResolver, updatedAnswers, copyableOptions);
  copyAnswersVisitor.visitQuestionnaire(questionnaire);

  return copyAnswersVisitor.getDefaultAnswers();
}
