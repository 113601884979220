import { Result } from '@breathelife/result';
import {
  IAnswerResolver,
  RepeatedAnswersBySurrogateId,
  Answers,
  QuestionnaireBlueprint,
  NodeInstance,
  SurrogateId,
  InstanceScope,
  InstanceIndex,
} from '@breathelife/types';

import { AnswerPath } from './AnswerPath';
import { NodeIdAnswersResolver } from './NodeIdAnswersResolver';
import { makeAnswerPathTreeFromBlueprint } from './makeAnswerPathTreeFromBlueprint';
import { LinkedBlueprintIds, makeLinkedBlueprintIdMap } from './makeLinkedBlueprintIdMap';
import { makeNodeIdToAnswerPathMap } from './makeNodeIdToAnswerPathMap';

export class BlueprintIdAnswersResolver implements IAnswerResolver {
  private readonly answerResolver: NodeIdAnswersResolver;
  private readonly linkedBlueprintIds: LinkedBlueprintIds;

  private static readonly NODE_REFERENCE_PATH_PREFIX = '';

  private constructor(
    idToAnswerPath: Map<string, AnswerPath>,
    linkedBlueprintIds: LinkedBlueprintIds,
    insuredPeopleHackId: string,
  ) {
    this.answerResolver = new NodeIdAnswersResolver(idToAnswerPath, insuredPeopleHackId);
    this.linkedBlueprintIds = linkedBlueprintIds;
  }
  public setAnswers(
    items: { id: string; value: unknown }[],
    answers: Answers,
    scope?: InstanceScope | undefined,
    answersForPath?: Answers | undefined,
  ): void {
    for (const item of items) {
      this.setAnswer(item.value, answers, item.id, scope, answersForPath);
    }
  }

  static from(blueprint: QuestionnaireBlueprint): BlueprintIdAnswersResolver {
    const tree = makeAnswerPathTreeFromBlueprint(blueprint);

    const idToAnswerPath = makeNodeIdToAnswerPathMap(tree);
    const linkedBlueprintIds = makeLinkedBlueprintIdMap(blueprint);

    const insuredPeopleBlueprintId = blueprint?.sectionGroupBlueprints?.insuredPeople?.id;
    if (!insuredPeopleBlueprintId) {
      throw new Error(
        "Can't create a BlueprintIdAnswersResolver because it's missing the insured-people section group",
      );
    }

    return new BlueprintIdAnswersResolver(idToAnswerPath, linkedBlueprintIds, insuredPeopleBlueprintId);
  }

  public static buildReferencePathKey(value: string | number): string {
    return `${BlueprintIdAnswersResolver.NODE_REFERENCE_PATH_PREFIX}${value}`;
  }

  public knowsId(id: string): boolean {
    return this.answerResolver.knowsId(id);
  }

  getInstanceId(answers: Answers, id: string, scope: InstanceScope): Result<string, SurrogateId | undefined> {
    return this.answerResolver.getInstanceId(answers, id, scope);
  }

  setInstanceId(
    answers: Answers,
    id: string,
    scope: InstanceScope,
    forceInstanceId?: string | undefined,
    idIsLeaf?: boolean,
  ): Result<string, SurrogateId> {
    return this.answerResolver.setInstanceId(answers, id, scope, forceInstanceId, idIsLeaf);
  }

  public getAnswer(
    answers: Answers,
    id: string,
    scope?: InstanceScope,
    answersForPath?: Answers,
    skipLeafIdentifier?: boolean,
  ): any | undefined {
    return this.answerResolver.getAnswer(answers, id, scope, answersForPath, skipLeafIdentifier);
  }

  public getCollection(answers: Answers, id: string, scope?: InstanceScope): any[] | undefined {
    return this.answerResolver.getCollection(answers, id, scope);
  }

  public isCollection(id: string): boolean {
    return this.answerResolver.isCollection(id);
  }

  public getRepeatedAnswers<T extends string>(
    answers: Answers,
    collectionId: string,
    ids: T[],
    scope: InstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    return this.answerResolver.getRepeatedAnswers(answers, collectionId, ids, scope);
  }

  public getRepetitionCount(answers: Answers, collectionId: string, scope: InstanceScope): number | undefined {
    return this.answerResolver.getRepetitionCount(answers, collectionId, scope);
  }

  public setAnswer(
    value: unknown,
    answers: Answers,
    id: string,
    scope?: InstanceScope,
    answersForPath?: Answers,
  ): void {
    this.answerResolver.setAnswer(value, answers, id, scope, answersForPath);

    const linkedIds = this.linkedBlueprintIds.get(id) || [];

    linkedIds.forEach((linkedId) => {
      this.answerResolver.setAnswer(value, answers, linkedId, scope, answersForPath);
    });
  }

  public unsetAnswer(answers: Answers, id: string, scope?: InstanceScope, answersForPath?: Answers): boolean {
    const linkedIds = this.linkedBlueprintIds.get(id) || [];

    linkedIds.forEach((linkedId) => this.answerResolver.unsetAnswer(answers, linkedId, scope, answersForPath));

    return this.answerResolver.unsetAnswer(answers, id, scope, answersForPath);
  }

  unsetAnswers(answers: Answers, nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, scope } = nodeInstance;
      const wasRemoved = this.unsetAnswer(answers, id, scope);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  public unsetAnswerSelectOptionId(answers: Answers, id: string, optionId: string, scope?: InstanceScope): boolean {
    const linkedIds = this.linkedBlueprintIds.get(id) || [];

    linkedIds.forEach((linkedId) => this.answerResolver.unsetAnswerSelectOptionId(answers, linkedId, optionId, scope));

    return this.answerResolver.unsetAnswerSelectOptionId(answers, id, optionId, scope);
  }

  public withCollectionIdentifier(
    identifiers: InstanceScope,
    collectionId: string,
    collectionIdentifier: InstanceIndex,
  ): InstanceScope {
    return this.answerResolver.withCollectionIdentifier(identifiers, collectionId, collectionIdentifier);
  }

  public removeUndefinedAnswersFromCollection(id: string, answers: Answers, scope?: InstanceScope): boolean {
    return this.answerResolver.removeUndefinedAnswersFromCollection(id, answers, scope);
  }
}
