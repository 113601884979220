import _ from 'lodash';

import { InsuranceModule } from '@breathelife/types';

import { QuestionnaireDefinition, Section } from '../structure';
import { Localized } from '../locale';

export function filterQuestionnaireSectionsByInsuranceModule(
  questionnaire: Localized<QuestionnaireDefinition>,
  insuranceModules: InsuranceModule[],
): Localized<QuestionnaireDefinition> {
  if (!insuranceModules.length) {
    return questionnaire;
  }

  return questionnaire.map((sectionGroup) => {
    const filteredSectionGroupSections = filterSectionsByInsuranceModules(sectionGroup.sections, insuranceModules);
    return { ...sectionGroup, sections: filteredSectionGroupSections };
  });
}

function filterSectionsByInsuranceModules(
  sections: Localized<Section>[],
  insuranceModules: InsuranceModule[],
): Localized<Section>[] {
  return sections.filter((section) => {
    // include the sections without a filter
    if (!section.insuranceModuleFilter) return true;

    const matches = _.intersection(section.insuranceModuleFilter, insuranceModules);
    return !_.isEmpty(matches);
  });
}
