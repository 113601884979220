import { Conditions, Query } from '@breathelife/types';

export type ValidityRule<TText = any> = ValidityRuleWithMessage<TText>;

type ValidityRuleWithMessage<TText> = BaseValidityRule & {
  message: TText;
};

type BaseValidityRule = {
  conditions: Conditions;
  messageParams?: MessageParams;
};

export type MessageParams = { [key: string]: undefined | number | string | MessageQuery };
export type EvaluatedMessageParams = { [key: string]: number | string };

export type MessageQuery = { fromQuery: Query };

export function isMessageQuery(obj: any): obj is MessageQuery {
  return !!obj && typeof (obj as MessageQuery).fromQuery !== 'undefined';
}
