import { fieldValueIsTrueBool, fieldValueMaximumCharacters } from '@breathelife/condition-engine';
import { ComparisonOperator, Conditions, Localizable } from '@breathelife/types';

import { ValidityRule } from '../nodeEvaluation';

/**
 * Helper that belongs in a validIf that returns a validation error if the value
 * at the provided nodeId is below the minimum controlValue provided
 *
 * @param nodeId The nodeId of the dynamic value to be compared
 * @param controlValue The static value to compare to
 * @param operator The comparison operator: > (ComparisonOperator.greaterThan) or >= (ComparisonOperator.greaterThanOrEqual)
 */
export function valueAboveMinimum(
  nodeId: string,
  controlValue: number,
  operator: ComparisonOperator.greaterThan | ComparisonOperator.greaterThanOrEqual,
): ValidityRule {
  return {
    conditions: { conditions: [{ nodeId, value: controlValue, operator }] },
    message: { en: `The minimum is ${controlValue}`, fr: `Le minimum est ${controlValue}` },
  };
}

/**
 * Helper that belongs in a validIf that returns a validation error if the value
 * at the provided nodeId is above the maximum controlValue provided
 *
 * @param nodeId The nodeId of the dynamic value to be compared
 * @param controlValue The static value to compare to
 * @param operator The comparison operator: < (ComparisonOperator.lessThan) or <= (ComparisonOperator.lessThanOrEqual)
 */
export function valueBelowMaximum(
  nodeId: string,
  controlValue: number,
  operator: ComparisonOperator.lessThan | ComparisonOperator.lessThanOrEqual,
): ValidityRule {
  return {
    conditions: { conditions: [{ nodeId, value: controlValue, operator }] },
    message: { en: `The maximum is ${controlValue}`, fr: `Le maximum est ${controlValue}` },
  };
}

/**
 * Helper that belongs in a validIf that returns a validation error if the character count
 * of the value at the provided nodeId is above the maximum controlValue provided
 *
 * @param nodeId The nodeId of the dynamic value to be compared
 * @param controlValue The static value to compare to
 */
export function valueDoesNotExceedCharacterLimit(nodeId: string, controlValue: number): ValidityRule {
  return {
    conditions: { conditions: [fieldValueMaximumCharacters(nodeId, controlValue)] },
    message: {
      en: `The maximum allowed characters is ${controlValue}`,
      fr: `La limite maximale de caractères est ${controlValue}`,
    },
  };
}

export function agreeFieldIsAccepted(nodeId: string, message?: Localizable): ValidityRule {
  const defaultMessage = {
    en: 'Please read and accept the notice',
    fr: 'Veuillez lire et accepter le préavis',
  };

  const acceptedCondition: Conditions = {
    conditions: [fieldValueIsTrueBool(nodeId)],
  };
  return {
    conditions: acceptedCondition,
    message: message || defaultMessage,
  };
}
