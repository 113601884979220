import _ from 'lodash';

import { evaluateConditions, evaluateQuery } from '@breathelife/condition-engine';
import { BooleanOperator, InstanceScope, NodeInstance, Answers, IAnswerResolver, Timezone } from '@breathelife/types';

import { ExpandedRepetitionsVisitor } from '../expandedContext/ExpandedRepetitionsVisitor';
import { VisibilityDependencyMap } from '../nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from '../nodeEvaluation/visibleIf/filterVisibleAnswers';
import { Field, QuestionnaireDefinition } from '../structure';
import { Localized } from '../locale';

class ComputedAnswersVisitor extends ExpandedRepetitionsVisitor {
  private visibilityDependencyMap: VisibilityDependencyMap;
  private changedNodeInstances: NodeInstance[] = [];
  private timezone: Timezone;

  constructor(
    answersResolver: IAnswerResolver,
    answers: Answers,
    visibilityDependencyMap: VisibilityDependencyMap,
    timezone: Timezone,
  ) {
    super(answersResolver, answers);
    this.visibilityDependencyMap = visibilityDependencyMap;
    this.timezone = timezone;
  }

  public getComputedAnswers(questionnaire: Localized<QuestionnaireDefinition>): Answers {
    this.visitQuestionnaire(questionnaire);
    return this.answers;
  }

  public getChangedNodeInstances(): NodeInstance[] {
    return _.uniqWith(this.changedNodeInstances, _.isEqual);
  }

  protected visitField(field: Localized<Field>): void {
    const { nodeId, computedValue } = field;
    if (!computedValue) {
      return;
    }

    const scope = this.repeatedInstanceIdentifiers();
    if (!this.isFieldVisible(field, scope)) {
      return;
    }

    const computedFieldValue = evaluateQuery(
      computedValue,
      this.answers ?? {},
      this.answersResolver,
      scope,
      this.timezone,
    );

    const currentAnswer = this.answersResolver.getAnswer(this.answers, nodeId, scope);
    if (computedFieldValue === currentAnswer) {
      return;
    }

    this.answersResolver.setAnswer(computedFieldValue, this.answers, nodeId, scope);
    this.changedNodeInstances.push({ id: nodeId, scope });
  }

  private isFieldVisible(field: Localized<Field>, scope: InstanceScope): boolean {
    const fieldVisibilityConditions = this.visibilityDependencyMap.getVisibilityConditions(field.nodeId);
    const visibilityConditions = fieldVisibilityConditions?.field;
    const isFieldVisible =
      !visibilityConditions ||
      evaluateConditions(
        { conditions: visibilityConditions, operator: BooleanOperator.or },
        this.answers,
        this.answersResolver,
        scope,
        this.timezone,
      ).isValid;
    return isFieldVisible;
  }
}

export function computedQuestionnaireAnswers(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  existingAnswers: Answers,
  timezone: Timezone,
): Answers {
  const computedAnswersVisitor = new ComputedAnswersVisitor(answersResolver, existingAnswers, dependencyMap, timezone);

  let updatedAnswers = computedAnswersVisitor.getComputedAnswers(questionnaire);

  // Setting computed answers may affect the visibility of other answers.
  for (const nodeInstance of computedAnswersVisitor.getChangedNodeInstances()) {
    updatedAnswers = filterVisibleAnswers(
      nodeInstance.id,
      dependencyMap,
      answersResolver,
      updatedAnswers,
      nodeInstance.scope,
      timezone,
    );
  }

  return updatedAnswers;
}
