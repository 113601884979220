import { Conditions, Query } from '@breathelife/types';

export type DefaultValue = DefaultValueQuery | any;

export type DefaultIfRule = {
  conditions: Conditions | null;
  defaultValue: DefaultValue;
};

type DefaultValueQuery = {
  fromQuery: Query;
};

export function isDefaultValueQuery(defaultValue: DefaultValue): defaultValue is DefaultValueQuery {
  return !!defaultValue && typeof (defaultValue as DefaultValueQuery).fromQuery !== 'undefined';
}
