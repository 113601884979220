import _ from 'lodash';

import { Answers } from '@breathelife/types';

export function moveAnswersToNewPaths(answers: Answers, paths: { origin: string; destination: string }[]): Answers {
  const answersWithModifiedStructure = _.cloneDeep(answers);
  for (const path of paths) {
    if (_.has(answersWithModifiedStructure, path.origin)) {
      const answer = _.get(answersWithModifiedStructure, path.origin);
      _.set(answersWithModifiedStructure, path.destination, answer);
      _.unset(answersWithModifiedStructure, path.origin);
    }
  }
  return answersWithModifiedStructure;
}
