import { Answers, Timezone, IAnswerResolver } from '@breathelife/types';

import { TextGetter } from '../../locale';
import { TransitionNodeWithMetadata, TransitionNodeWithVisibility } from '../../renderingTransforms';
import { TransformEvaluationVisitorAdapter } from '../../renderingTransforms/TransformVisitor';
import { applyTemplate } from '../../utils/stringTemplate';
import { EvaluatedVisibilityQuestionnaire } from '../visibleIf/visibility';
import { createValidityVisitor, EvaluatedRule, ValidityNode } from './ValidityVisitor';

type Options = { shouldValidateAllAnswers: boolean };

export type EvaluatedValidityQuestionnaire = (TransitionNodeWithMetadata &
  TransitionNodeWithVisibility & {
    sections: (TransitionNodeWithMetadata &
      TransitionNodeWithVisibility & {
        subsections: (TransitionNodeWithVisibility & {
          questions: (TransitionNodeWithMetadata &
            TransitionNodeWithVisibility & {
              fields: (TransitionNodeWithMetadata & TransitionNodeWithVisibility & ValidityNode)[];
            })[];
        })[];
      })[];
  })[];

export function buildValidationErrorMessage(evaluatedRule: EvaluatedRule): string {
  const { message, evaluatedMessageParams } = evaluatedRule;

  let evaluatedMessage: string = '';
  if (message && typeof message === 'string') {
    evaluatedMessage = applyTemplate(message, evaluatedMessageParams);
  } else {
    throw new Error('A message or message key must be defined for a ValidityRule.');
  }
  return evaluatedMessage;
}

function transformItemsValidity(
  questionnaire: EvaluatedVisibilityQuestionnaire,
  answers: Answers,
  answersResolver: IAnswerResolver,
  text: TextGetter,
  { shouldValidateAllAnswers }: Options,
  timezone: Timezone,
): EvaluatedValidityQuestionnaire {
  const validityVisitor = createValidityVisitor(
    {
      processFieldEvaluation: ({ node, hasBeenAnswered, brokenRules, validationData }) => {
        const isValid = brokenRules.length === 0;
        node.valid = node.valid && isValid;
        node.validationData = validationData;

        const shouldSetValidationMessage = shouldValidateAllAnswers || hasBeenAnswered;
        if (!isValid && shouldSetValidationMessage && !node.validationError) {
          const firstBrokenRule = brokenRules[0];
          node.validationError = { message: buildValidationErrorMessage(firstBrokenRule) };
        }
      },
    },
    answersResolver,
    timezone,
  );

  const visitorAdapter = new TransformEvaluationVisitorAdapter(answers, validityVisitor);
  visitorAdapter.visitQuestionnaire(questionnaire);
  return questionnaire;
}

export { transformItemsValidity };
