import { SelectOption } from '../structure';

export const emptyTitle = { en: '', fr: '' };

export function yesNoOptions(): SelectOption[] {
  return [
    { id: 'yes', text: { fr: 'Oui', en: 'Yes' } },
    { id: 'no', text: { fr: 'Non', en: 'No' } },
  ];
}
