import _ from 'lodash';

import { evaluateConditions } from '@breathelife/condition-engine';
import {
  ConditionalConsideration,
  Consideration,
  ConsiderationReason,
  OutcomeCode,
  OutcomeConsiderations,
  Answers,
  Timezone,
  InstanceScope,
} from '@breathelife/types';

import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from '../answersResolver';

export type OutcomeSchema = {
  // Determines the order in which results will be considered.
  orderedTriggerCriteria: OutcomeCodeTriggerCriteria[];

  // The outcome if no results from `orderedResultTriggerCriteria` are triggered.
  fallbackOutcome: Outcome;
};

export type OutcomeCodeTriggerCriteria = { outcomeCode: OutcomeCode; triggerCriteria: TriggerCriteria };

export type Outcome = {
  outcomeCode: OutcomeCode;
  explanation: Explanation;
};

export type Explanation = {
  reasons: ConsiderationReason[]; // Reasons matching the `triggerCriteria`.
  ignoredReasons?: ConsiderationReason[]; // Reasons that did not match the `triggerCriteria`.
  triggerCriteria?: TriggerCriteria;
};

type TriggerCriteria = {
  triggerWhen: 'anyTrue' | 'allTrue' | 'anyFalse' | 'allFalse';
  triggersWithNoConsiderations?: boolean;
};

export const defaultOutcomeSchema: OutcomeSchema = {
  fallbackOutcome: { outcomeCode: OutcomeCode.accepted, explanation: { reasons: [] } },
  /*
  The order in which we evaluate the outcomes are important here. The outcomes (blocked/blockedAtQuoter)
  should have higher priority as they impact the user differently during the consumer flow and prevent the user from 
  proceeding during the consumer flow. It's important to note, as soon as any of the outcome evaluations are triggered and evaluate to true
  then the outcomes after that will not be evaluated. 
  */
  orderedTriggerCriteria: [
    { outcomeCode: OutcomeCode.blockedAtQuoter, triggerCriteria: { triggerWhen: 'anyTrue' } },
    { outcomeCode: OutcomeCode.blocked, triggerCriteria: { triggerWhen: 'anyTrue' } },
    { outcomeCode: OutcomeCode.denied, triggerCriteria: { triggerWhen: 'anyTrue' } },
    { outcomeCode: OutcomeCode.conditional, triggerCriteria: { triggerWhen: 'anyTrue' } },
    { outcomeCode: OutcomeCode.referred, triggerCriteria: { triggerWhen: 'anyTrue' } },
  ],
};

export class OutcomeDeterminator {
  private readonly outcomeSchema: OutcomeSchema = defaultOutcomeSchema;
  private readonly outcomeConsiderations: OutcomeConsiderations = {
    blockedAtQuoter: [],
    denied: [],
    referred: [],
    conditional: [],
    accepted: [],
    blocked: [],
    unknown: [],
    ignored: [],
    notApplicable: [],
  };

  private readonly timezone: Timezone;

  constructor(schema: OutcomeSchema | undefined, timezone: Timezone) {
    if (schema) this.outcomeSchema = schema;
    this.timezone = timezone;
  }

  /** Record a Consideration with an already-known value */
  public recordConsideration(consideration: Consideration): void {
    this.outcomeConsiderations[consideration.outcomeCode].push(consideration);
  }

  /** Record a Consideration whose value is set by conditions */
  public recordConditionalConsideration(
    conditionalConsideration: ConditionalConsideration,
    answers: Answers,
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
    scope: InstanceScope = {},
  ): void {
    const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
    const value = evaluateConditions(conditionalConsideration, answers, answersResolver, scope, this.timezone).isValid;

    const { outcomeCode, reason, ruleId, ruleIdentifier } = conditionalConsideration;

    if (!ruleId) {
      throw new Error('Conditional consideration is missing a ruleID');
    }

    const consideration: Consideration = {
      value,
      outcomeCode,
      reason,
      ruleId,
      ruleIdentifier: ruleIdentifier ?? null,
    };
    this.recordConsideration(consideration);
  }

  /** Record multiple Considerations whose value is set by conditions */
  public recordConditionalConsiderations(
    conditionalConsiderations: ConditionalConsideration[],
    answers: Answers,
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
    scope: InstanceScope = {},
  ): void {
    conditionalConsiderations.forEach((conditionalConsideration) =>
      this.recordConditionalConsideration(conditionalConsideration, answers, nodeIdToAnswerPathMap, scope),
    );
  }

  /** Compare all currently known Considerations against the OutcomeSchema to determine an Outcome */
  public getOutcome(): Outcome {
    const outcome = this.getFirstTriggeredOutcome();
    if (typeof outcome === 'undefined') {
      return _.cloneDeep(this.outcomeSchema.fallbackOutcome);
    }

    return outcome;
  }

  /** Get a snapshot of the outcome considerations */
  public getRecordedConsiderations(): OutcomeConsiderations {
    return this.outcomeConsiderations;
  }

  private getFirstTriggeredOutcome(): Outcome | undefined {
    let outcome: Outcome | undefined = undefined;

    const { orderedTriggerCriteria } = this.outcomeSchema;
    for (let i = 0; !outcome && i < orderedTriggerCriteria.length; i++) {
      const { outcomeCode, triggerCriteria } = orderedTriggerCriteria[i];

      const isTriggered = this.isOutcomeTriggered(outcomeCode, triggerCriteria);
      if (isTriggered) {
        const explanation = this.getExplanation(outcomeCode, triggerCriteria);
        outcome = { outcomeCode: outcomeCode, explanation };
      }
    }

    return outcome;
  }

  private isOutcomeTriggered(outcomeCode: OutcomeCode, triggerCriteria: TriggerCriteria): boolean {
    const considerationsForOutcomeCode = this.outcomeConsiderations[outcomeCode];

    if (!considerationsForOutcomeCode.length) {
      // This provides flexibility, and prevents `allTrue` and `allFalse` from returning true by default (when there are no considerations).
      return !!triggerCriteria.triggersWithNoConsiderations;
    }

    switch (triggerCriteria.triggerWhen) {
      case 'anyTrue':
        return considerationsForOutcomeCode.some((part) => part.value);
      case 'anyFalse':
        return considerationsForOutcomeCode.some((part) => !part.value);
      case 'allTrue':
        return considerationsForOutcomeCode.every((part) => part.value);
      case 'allFalse':
        return considerationsForOutcomeCode.every((part) => !part.value);
    }
  }

  /** Why the outcome was chosen */
  private getExplanation(outcomeCode: OutcomeCode, triggerCriteria: TriggerCriteria): Explanation {
    const considerationsForOutcomeCode = this.outcomeConsiderations[outcomeCode];

    const reasons: ConsiderationReason[] = [];
    const ignoredReasons: ConsiderationReason[] = [];

    considerationsForOutcomeCode.forEach((part) => {
      switch (triggerCriteria.triggerWhen) {
        case 'allTrue':
        case 'anyTrue':
          if (part.value) {
            reasons.push(part.reason);
          } else {
            ignoredReasons.push(part.reason);
          }
          break;
        case 'allFalse':
        case 'anyFalse':
          if (!part.value) {
            reasons.push(part.reason);
          } else {
            ignoredReasons.push(part.reason);
          }
          break;
      }
    });

    return {
      reasons,
      ignoredReasons,
      triggerCriteria: _.cloneDeep(triggerCriteria),
    };
  }
}
