import { Answers } from '@breathelife/types';

export type DataEncryptionFunction = (data: Buffer, kmsKeyPath: string, passThroughMode?: boolean) => Promise<Buffer>;

export type EncryptionConfig = {
  kmsKeyPath: string;
  passThroughMode?: boolean;
};

export class AnswersEncryption {
  private readonly encrypt: DataEncryptionFunction;
  private readonly decrypt: DataEncryptionFunction;
  private readonly config: EncryptionConfig;

  constructor(encrypt: DataEncryptionFunction, decrypt: DataEncryptionFunction, config: EncryptionConfig) {
    this.encrypt = encrypt;
    this.decrypt = decrypt;
    this.config = config;
  }

  public async encryptApplicationAnswers(answers: Answers): Promise<Buffer> {
    return await this.encryptData(answers);
  }

  public async decryptApplicationAnswers(encryptedAnswers: Buffer): Promise<Answers> {
    return await this.decryptData<Answers>(encryptedAnswers);
  }

  private async encryptData(decryptedData: any): Promise<Buffer> {
    const stringifiedData = JSON.stringify(decryptedData);
    return this.encrypt(Buffer.from(stringifiedData), this.config.kmsKeyPath, this.config.passThroughMode);
  }

  private async decryptData<T = unknown>(encryptedData: Buffer): Promise<T> {
    const decryptedBuffer = await this.decrypt(encryptedData, this.config.kmsKeyPath, this.config.passThroughMode);
    return JSON.parse(decryptedBuffer.toString());
  }
}
