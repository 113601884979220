import _ from 'lodash';

import { Conditions, Localizable } from '@breathelife/types';

import { Question, Subsection } from '../../structure';
import { validateSubsection } from '../../structureValidations';
import FieldBuilder from './FieldBuilder';
import NodeBuilder from './NodeBuilder';
import QuestionBuilder from './QuestionBuilder';
import { SeedProviders } from './SeedProviders';

export default class SubsectionBuilder extends NodeBuilder<Subsection> {
  private readonly seedProviders: SeedProviders;

  public constructor(seedProviders: SeedProviders) {
    super(seedProviders.forSubsection());
    this.seedProviders = seedProviders;
  }

  public with(nodeBuilder: QuestionBuilder | FieldBuilder): this {
    if (nodeBuilder instanceof QuestionBuilder) {
      return this.withQuestions(nodeBuilder);
    }
    return this.withQuestions(this.childBuilder().with(nodeBuilder));
  }

  public withQuestions(questionBuilders?: QuestionBuilder[] | QuestionBuilder): this {
    const builders = questionBuilders ? _.castArray(questionBuilders) : [];

    if (builders.length === 0) {
      builders.push(this.childBuilder());
    }

    const questions = builders.map((builder) => builder.build());
    this.addQuestions(questions);

    return this.clone();
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    this.addQuestions(this.childBuilder().withFields(fieldBuilders).build());

    return this.clone();
  }

  private childBuilder(): QuestionBuilder {
    return new QuestionBuilder(this.seedProviders);
  }

  private addQuestions(questions: Question | Question[]): void {
    if (!this.node.questions) this.node.questions = [];

    const questionList = _.castArray(questions);
    this.node.questions.push(...questionList);
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public withId(id: string): this {
    this.node.id = id;
    return this.clone();
  }

  public visibleIf(condition: Conditions): this {
    this.node.visibleIf = condition;

    return this.clone();
  }

  public withProperties(properties: Partial<Subsection>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public validate(subsection: Subsection): subsection is Subsection {
    return validateSubsection(subsection);
  }

  public withText(text: Localizable): this {
    this.node.text = text;
    return this.clone();
  }
}
