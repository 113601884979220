import _ from 'lodash';

// Matches all "%{placeholder}" over multiple lines with a capture group on the placeholder name.
const placeholderMatcher = /(?:%{)(.*?)(?:})/gm;

type ReplacerFunction = (substring: string, ...args: any[]) => string;

type Options = { [key: string]: number | string };

function applyTemplate(message: string, options: Options = {}): string {
  return message.replace(placeholderMatcher, buildReplacer(options));
}

function buildReplacer(options: Options): ReplacerFunction {
  return (match: string, optionName: string): string => {
    const option = options[optionName];
    if (typeof option !== 'undefined') {
      return _.toString(option);
    }
    return `** missing ${optionName} value **`;
  };
}

export { applyTemplate };
