import {
  TransitionField,
  TransitionOption,
  TransitionQuestion,
  TransitionQuestionnaire,
  TransitionSection,
  TransitionSectionGroup,
  TransitionSubsection,
} from './Questionnaire';

abstract class TransitionVisitor {
  private stopRequested: boolean = false;

  public visitQuestionnaire(questionnaire: TransitionQuestionnaire): void {
    if (questionnaire?.length > 0) {
      for (const sectionGroup of questionnaire) {
        if (this.stopRequested) break;
        this.visitSectionGroup(sectionGroup);
      }
    }
  }

  protected visitSectionGroup(sectionGroup: TransitionSectionGroup): void {
    if (sectionGroup.sections?.length > 0) {
      for (const section of sectionGroup.sections) {
        if (this.stopRequested) break;
        this.visitSection(section);
      }
    }
  }
  protected visitSection(section: TransitionSection): void {
    if (section.subsections?.length > 0) {
      for (const subsection of section.subsections) {
        if (this.stopRequested) break;
        this.visitSubsection(subsection);
      }
    }
  }
  protected visitSubsection(subsection: TransitionSubsection): void {
    if (subsection.questions?.length > 0) {
      for (const question of subsection.questions) {
        if (this.stopRequested) break;
        this.visitQuestion(question);
      }
    }
  }
  protected visitQuestion(question: TransitionQuestion): void {
    if (question.fields?.length > 0) {
      for (const field of question.fields) {
        if (this.stopRequested) break;
        this.visitField(field);
      }
    }
  }
  protected visitField(field: TransitionField): void {
    if (field.options?.length > 0) {
      for (const option of field.options) {
        if (this.stopRequested) break;
        this.visitOption(option);
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected visitOption(option: TransitionOption): void {}

  protected stopVisit = (): void => {
    this.stopRequested = true;
  };
}

export { TransitionVisitor };
