import _ from 'lodash';

import { PlatformType } from '@breathelife/types';

import QuestionnaireVisitor from '../QuestionnaireVisitor';
import {
  BaseNode,
  Field,
  isOptionField,
  Question,
  QuestionnaireDefinition,
  Section,
  SectionGroup,
  Subsection,
} from '../structure';
import { Localized } from '../locale';

class FilterNodesByUserTypesVisitor extends QuestionnaireVisitor {
  private readonly userTypes: PlatformType[];

  constructor(userTypes: PlatformType[]) {
    super();
    this.userTypes = userTypes;
  }

  public visitQuestionnaire(questionnaire: Localized<QuestionnaireDefinition>): {
    output: void;
    questionnaire: Localized<QuestionnaireDefinition>;
  } {
    const clonedQuestionnaire: Localized<QuestionnaireDefinition> = _.cloneDeep(questionnaire);
    const filteredQuestionnaire = this.filterNodesByPlatformType(clonedQuestionnaire);
    super.visitQuestionnaire(filteredQuestionnaire);

    return { questionnaire: filteredQuestionnaire, output: undefined };
  }

  public visitSectionGroup(sectionGroup: Localized<SectionGroup>): void {
    sectionGroup.sections = this.filterNodesByPlatformType(sectionGroup.sections);
    super.visitSectionGroup(sectionGroup);
  }

  public visitSection(section: Localized<Section>): void {
    section.subsections = this.filterNodesByPlatformType(section.subsections);
    super.visitSection(section);
  }

  public visitSubsection(subsection: Localized<Subsection>): void {
    subsection.questions = this.filterNodesByPlatformType(subsection.questions);
    super.visitSubsection(subsection);
  }

  public visitQuestion(question: Localized<Question>): void {
    question.fields = this.filterNodesByPlatformType(question.fields);
    super.visitQuestion(question);
  }

  public visitField(field: Localized<Field>): void {
    if (isOptionField(field)) {
      if (field.options?.length > 0) {
        field.options = this.filterNodesByPlatformType(field.options);
      }
    }
  }

  private filterNodesByPlatformType<T extends Localized<BaseNode>>(nodes: T[]): T[] {
    return nodes.filter((node) => this.nodeHasMatchingPlatformTypes(node));
  }

  private nodeHasMatchingPlatformTypes(baseNode: Localized<BaseNode>): boolean {
    if (!baseNode.platformTypeFilter?.length) {
      // No platform type means this should always be included.
      return true;
    }

    const matches = _.intersection(baseNode.platformTypeFilter, this.userTypes);
    return !_.isEmpty(matches);
  }
}

export function filterNodesByPlatformType(
  questionnaire: Localized<QuestionnaireDefinition>,
  userTypes: PlatformType[],
): Localized<QuestionnaireDefinition> {
  if (!userTypes.length) {
    return questionnaire;
  }

  const visitor = new FilterNodesByUserTypesVisitor(userTypes);
  const { questionnaire: filteredQuestionnaire } = visitor.visitQuestionnaire(questionnaire);

  return filteredQuestionnaire;
}
