import { Answers } from '@breathelife/types';

import {
  ExtractRuleFunction,
  NodeEvaluationVisit,
  NodeType,
  VisitNodeFunction,
} from '../nodeEvaluation/NodeEvaluationVisit';
import {
  TransitionField,
  TransitionOption,
  TransitionQuestion,
  TransitionQuestionnaire,
  TransitionSection,
  TransitionSectionGroup,
  TransitionSubsection,
} from './Questionnaire';
import { TransitionVisitor } from './TransitionVisitor';

interface TransformEvaluationVisitor<TOut, TCompleteOutput> {
  visitDefault: VisitNodeFunction<any, TOut>;
  visitSectionGroup?: VisitNodeFunction<TransitionSectionGroup, TOut>;
  visitSection?: VisitNodeFunction<TransitionSection, TOut>;
  visitSubsection?: VisitNodeFunction<TransitionSubsection, TOut>;
  visitQuestion?: VisitNodeFunction<TransitionQuestion, TOut>;
  visitField?: VisitNodeFunction<TransitionField, TOut>;
  visitOption?: VisitNodeFunction<TransitionOption, TOut>;
  complete: () => TCompleteOutput;
}

class TransformEvaluationVisitorAdapter<TOut> extends TransitionVisitor {
  private readonly nodeEvaluationVisit: NodeEvaluationVisit<TOut, TransformEvaluationVisitor<TOut, unknown>>;

  public constructor(
    answers: Answers,
    evalVisitor: TransformEvaluationVisitor<TOut, unknown>,
    extractRule?: ExtractRuleFunction,
  ) {
    super();
    this.nodeEvaluationVisit = new NodeEvaluationVisit(answers, evalVisitor, extractRule);
  }

  public visitQuestionnaire(questionnaire: TransitionQuestionnaire): void {
    return super.visitQuestionnaire(questionnaire);
  }

  protected visitSectionGroup(sectionGroup: TransitionSectionGroup): void {
    this.nodeEvaluationVisit.visitFor(sectionGroup, () => super.visitSectionGroup(sectionGroup), {
      nodeType: NodeType.SectionGroup,
      repeatedInstanceIdentifiers: sectionGroup.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitSection(section: TransitionSection): void {
    this.nodeEvaluationVisit.visitFor(section, () => super.visitSection(section), {
      nodeType: NodeType.Section,
      repeatedInstanceIdentifiers: section.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitSubsection(subsection: TransitionSubsection): void {
    this.nodeEvaluationVisit.visitFor(subsection, () => super.visitSubsection(subsection), {
      nodeType: NodeType.Subsection,
      repeatedInstanceIdentifiers: subsection.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitQuestion(question: TransitionQuestion): void {
    this.nodeEvaluationVisit.visitFor(question, () => super.visitQuestion(question), {
      nodeType: NodeType.Question,
      repeatedInstanceIdentifiers: question.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitField(field: TransitionField): void {
    this.nodeEvaluationVisit.visitFor(field, () => super.visitField(field), {
      nodeType: NodeType.Field,
      repeatedInstanceIdentifiers: field.metadata.repeatedInstanceIdentifierContext,
    });
  }

  protected visitOption(option: TransitionOption): void {
    this.nodeEvaluationVisit.visitFor(option, () => super.visitOption(option), {
      nodeType: NodeType.Option,
      repeatedInstanceIdentifiers: option.metadata.repeatedInstanceIdentifierContext,
    });
  }
}

export { TransformEvaluationVisitorAdapter };
