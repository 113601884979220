import { QuestionnaireBlueprint } from '@breathelife/types';

enum BlueprintNodeIdKeys {
  nodeIds = 'nodeIds',
  answerNodeId = 'answerNodeId',
  targetNodeId = 'targetNodeId',
  repeatableAnswerNodeId = 'repeatableAnswerNodeId',
  targetBirthdateNodeId = 'targetBirthdateNodeId',
  heightNodeId = 'heightNodeId',
  weightNodeId = 'weightNodeId',
  startDateNodeId = 'startDateNodeId',
  endDateNodeId = 'endDateNodeId',
}

export function getNodeIdsFromBlueprints(blueprint?: QuestionnaireBlueprint): Set<string> {
  return new Set(findAllValuesByKeys(Object.values(BlueprintNodeIdKeys), blueprint));
}

function findAllValuesByKeys(keysToFind: string[], obj?: Record<string, any>): string[] {
  const results: string[] = [];

  if (!obj) {
    return results;
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (keysToFind.includes(key) && typeof value === 'string') {
      return acc.concat(value);
    }

    if (keysToFind.includes(key) && Array.isArray(value)) {
      return acc.concat(value);
    }

    if (typeof value === 'object') {
      return acc.concat(findAllValuesByKeys(keysToFind, value));
    }

    return acc;
  }, results);
}
