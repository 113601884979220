import { Answers, ApplicationContext, Language } from '@breathelife/types';
import _ from 'lodash';
import { EvaluationVisitor } from '../nodeEvaluation/EvaluationVisitor';
import { VisitNodeFunction } from '../nodeEvaluation/NodeEvaluationVisit';
import { TransitionField } from './Questionnaire';
import { RenderingFieldOption } from './RenderingQuestionnaire';
import { ExpandedQuestionnaire } from './RepeatableExpansion';
import { TransformEvaluationVisitorAdapter } from './TransformVisitor';

export function populateApplicationContextSelectOptions(
  questionnaire: ExpandedQuestionnaire,
  applicationContext: ApplicationContext,
  answers: Answers,
  language: Language,
): ExpandedQuestionnaire {
  const createApplicationContextSelectOptionsVisitor = createApplicationContextSelectOption(
    applicationContext,
    language,
  );

  const visitorAdapter = new TransformEvaluationVisitorAdapter(answers, createApplicationContextSelectOptionsVisitor);
  visitorAdapter.visitQuestionnaire(questionnaire);

  return questionnaire;
}

function createApplicationContextSelectOption(
  applicationContext: ApplicationContext,
  language: Language,
): EvaluationVisitor<void, void> {
  const visitField: VisitNodeFunction<TransitionField, void> = (navigation, { node }) => {
    if (node.optionsFromApplicationContext) {
      const { tag, labelKey, valuePath } = node.optionsFromApplicationContext;

      const applicationContextData = applicationContext[tag];

      if (!applicationContextData) {
        return;
      }

      if (!Array.isArray(applicationContextData)) {
        return;
      }

      const renderingFieldOptions: RenderingFieldOption[] = applicationContextData.map((acd) => {
        return {
          id: _.get(acd, valuePath, ''),
          text: _.get(acd, labelKey[language], ''),
          disabled: node.disabled,
          iconName: node.iconName,
          info: node.info,
          metadata: node.metadata,
          title: node.title,
          visible: node.visible,
        };
      });

      node.options = renderingFieldOptions;
    }
  };

  return {
    visitDefault: (navigation) => navigation.visitChildren(),
    visitField,
    complete: () => {},
  };
}
