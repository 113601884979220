import _ from 'lodash';

import { Conditions, Localizable } from '@breathelife/types';

import {
  Field,
  isRepeatableOptionsBasedOnCollection,
  isRepeatableOptionsWithLimits,
  Question,
  RepeatableQuestion,
} from '../../structure';
import { validateQuestion } from '../../structureValidations';
import FieldBuilder, { TextFieldBuilder } from './FieldBuilder';
import NodeBuilder from './NodeBuilder';
import { SeedProviders } from './SeedProviders';

export default class QuestionBuilder extends NodeBuilder<Question> {
  private readonly seedProviders: SeedProviders;

  public constructor(seedProviders: SeedProviders) {
    super(seedProviders.forQuestion());
    this.seedProviders = seedProviders;
  }

  public with(nodeBuilder: TextFieldBuilder): this {
    return this.withFields(nodeBuilder);
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    const builders = fieldBuilders ? _.castArray(fieldBuilders) : [];

    if (builders.length === 0) {
      builders.push(new TextFieldBuilder(this.seedProviders));
    }

    const fields = builders.map((builder) => builder.build());
    this.addFields(fields);

    return this.clone();
  }

  private addFields(fields: Field | Field[]): void {
    if (!this.node.fields) this.node.fields = [];

    const fieldList = _.castArray(fields);
    this.node.fields.push(...fieldList);
  }

  public seedWith(seed: Question): this {
    this.node = _.cloneDeep(seed);
    return this.clone();
  }

  public makeRepeatable({ nodeId }: { nodeId: string }): this {
    Object.assign(this.node, this.seedProviders.forRepeatableQuestionOptions());

    const repeatableQuestion = this.node as RepeatableQuestion;
    repeatableQuestion.nodeId = nodeId;

    return this.clone();
  }

  public withId(id: string): this {
    this.node.id = id;
    return this.clone();
  }

  public withMinRepetitions(minRepetitions: number): this {
    if (!this.node.options) {
      Object.assign(this.node, this.seedProviders.forRepeatableQuestionOptions());
      Object.assign(this.node.options ?? {}, { minRepetitions });
    } else {
      Object.assign(this.node.options, { minRepetitions });
    }

    return this.clone();
  }

  public withSelectTitle(nodeIds: string[]): this {
    if (!this.node.options) {
      throw new Error(
        "You can't add the 'selectTitle' property before the collection node id has been set in the options.",
      );
    }

    if (isRepeatableOptionsWithLimits(this.node.options)) {
      throw new Error("You can't add a 'selectTitle' property on a repeatable question that expects a min/max limit.");
    }

    if (isRepeatableOptionsBasedOnCollection(this.node.options)) {
      Object.assign(this.node.options, { selectTitle: nodeIds });
    }

    return this.clone();
  }

  public withSelectText(nodeIds: string[]): this {
    if (!this.node.options) {
      throw new Error(
        "You can't add the 'selectText' property before the collection node id has been set in the options.",
      );
    }

    if (isRepeatableOptionsWithLimits(this.node.options)) {
      throw new Error("You can't add a 'selectText' property on a repeatable question that expects a min/max limit.");
    }

    if (isRepeatableOptionsBasedOnCollection(this.node.options)) {
      Object.assign(this.node.options, { selectText: nodeIds });
    }

    return this.clone();
  }

  public withCollection(nodeId: string): this {
    if (!this.node.options || isRepeatableOptionsWithLimits(this.node.options)) {
      this.node.options = { expandToCollectionLength: nodeId, repeatable: true };
      return this.clone();
    }

    Object.assign(this.node.options, { expandToCollectionLength: nodeId });
    return this.clone();
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public withText(text: Localizable): this {
    this.node.text = text;
    return this.clone();
  }

  public visibleIf(condition: Conditions): this {
    this.node.visibleIf = condition;

    return this.clone();
  }

  public withProperties(properties: Partial<Question>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public seedFieldsWith(fields: Field[]): this {
    this.node.fields = _.cloneDeep(fields);
    return this.clone();
  }

  public validate(question: Question): question is Question {
    return validateQuestion(question);
  }
}
