import _ from 'lodash';

import { QuestionnaireDefinition } from '../../structure';
import BaseBuilder from './BaseBuilder';
import FieldBuilder from './FieldBuilder';
import QuestionBuilder from './QuestionBuilder';
import SectionBuilder from './SectionBuilder';
import SectionGroupBuilder from './SectionGroupBuilder';
import { SeedProviders } from './SeedProviders';
import SubsectionBuilder from './SubsectionBuilder';
import { Language } from '@breathelife/types';
import { localizeQuestionnaire } from '../../locale';

export default class QuestionnaireBuilder extends BaseBuilder<QuestionnaireDefinition> {
  private readonly seedProviders: SeedProviders;
  private questionnaire: QuestionnaireDefinition;

  constructor(seedProviders: SeedProviders) {
    super();
    this.seedProviders = seedProviders;
    this.questionnaire = [];
  }

  public with(
    nodeBuilder: SectionGroupBuilder | SectionBuilder | SubsectionBuilder | QuestionBuilder | FieldBuilder,
  ): this {
    if (nodeBuilder instanceof SectionGroupBuilder) {
      return this.withSectionGroups(nodeBuilder);
    }
    return this.withSectionGroups(new SectionGroupBuilder(this.seedProviders).with(nodeBuilder));
  }

  public withSectionGroups(sectionGroupBuilders?: SectionGroupBuilder[] | SectionGroupBuilder): this {
    const builders = sectionGroupBuilders ? _.castArray(sectionGroupBuilders) : [];

    if (builders.length === 0) {
      builders.push(new SectionGroupBuilder(this.seedProviders));
    }

    const sectionGroups = builders.map((builder) => builder.build());
    this.questionnaire.push(...sectionGroups);

    return this.clone();
  }

  public withSteps(sectionBuilders?: SectionBuilder[] | SectionBuilder): this {
    return this.withSections(sectionBuilders);
  }

  public withSections(sectionBuilders?: SectionBuilder[] | SectionBuilder): this {
    this.questionnaire.push(this.childBuilder().withSections(sectionBuilders).build());

    return this.clone();
  }

  public withSubsections(subsectionBuilders?: SubsectionBuilder[] | SubsectionBuilder): this {
    this.questionnaire.push(this.childBuilder().withSubsections(subsectionBuilders).build());

    return this.clone();
  }

  public withQuestions(questionBuilders?: QuestionBuilder[] | QuestionBuilder): this {
    this.questionnaire.push(this.childBuilder().withQuestions(questionBuilders).build());

    return this.clone();
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    this.questionnaire.push(this.childBuilder().withFields(fieldBuilders).build());

    return this.clone();
  }

  public build(): QuestionnaireDefinition {
    return _.cloneDeep(this.questionnaire);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public localize(language: Language = Language.en) {
    return {
      build: () => localizeQuestionnaire(_.cloneDeep(this.questionnaire), language),
    };
  }

  private childBuilder(): SectionGroupBuilder {
    return new SectionGroupBuilder(this.seedProviders);
  }
}
