import { AnswerPath, isReferencePath } from './AnswerPath';
import { NodeIdToAnswerPathMap } from './makeNodeIdToAnswerPathMap';

export type VerificationWarning = {
  nodeId: string;
  message: string;
};

export function verifyNodeIdToAnswerPathMap(
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  nodeIds: Set<string>,
): VerificationWarning[] {
  return [
    ...verifyAllNodeIdsExist(nodeIdToAnswerPathMap, nodeIds),
    ...verifyAllNodeIdsAreUsed(nodeIdToAnswerPathMap, nodeIds),
    ...verifyFromValueAtNodePaths(nodeIdToAnswerPathMap),
  ];
}

export function verifyAllNodeIdsAreUsed(
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  nodeIds: Set<string>,
): VerificationWarning[] {
  const warnings: VerificationWarning[] = [];

  for (const nodeId of nodeIds) {
    if (!nodeIdToAnswerPathMap.has(nodeId)) {
      warnings.push({
        nodeId,
        message: 'NodeId defined but missing from map',
      });
    }
  }

  return warnings;
}

function verifyAllNodeIdsExist(
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  nodeIds: Set<string>,
): VerificationWarning[] {
  const warnings: VerificationWarning[] = [];

  for (const [nodeId] of nodeIdToAnswerPathMap) {
    if (!nodeIds.has(nodeId)) {
      warnings.push({
        nodeId,
        message: 'NodeId does not exist',
      });
    }
  }

  return warnings;
}

function verifyFromValueAtNodePaths(nodeIdToAnswerPathMap: NodeIdToAnswerPathMap): VerificationWarning[] {
  const warnings: VerificationWarning[] = [];

  const nodeIdToDescendantNodeIds = getNodeIdToDescendantNodeIds(nodeIdToAnswerPathMap);

  const parentAnswerPaths = new Set<AnswerPath>();
  for (const [, answerPath] of nodeIdToAnswerPathMap) {
    if (answerPath.parent) {
      parentAnswerPaths.add(answerPath.parent);
    }
  }

  for (const [nodeId, answerPath] of nodeIdToAnswerPathMap) {
    if (isReferencePath(answerPath.path)) {
      const { fromValueAt } = answerPath.path;

      const fromValueAtAnswerPath = nodeIdToAnswerPathMap.get(fromValueAt);

      if (!fromValueAtAnswerPath) {
        warnings.push({
          nodeId: fromValueAt,
          message: 'fromValueAt nodeId does not exist',
        });
      } else if (parentAnswerPaths.has(fromValueAtAnswerPath)) {
        warnings.push({
          nodeId: fromValueAt,
          message: 'fromValueAt cannot target an answerPath with children',
        });
      } else if (
        fromValueAt === nodeId ||
        isNodeIdInDescendants(nodeIdToDescendantNodeIds, { nodeId, possibleDescendantNodeId: fromValueAt })
      ) {
        warnings.push({
          nodeId,
          message:
            'fromValueAt references itself or one of its own children. This is a circular reference that will result in a stack overflow',
        });
      }
    }
  }

  return warnings;
}

function isNodeIdInDescendants(
  nodeIdToDescendantNodeIds: Map<string, Set<string>>,
  { nodeId, possibleDescendantNodeId }: { nodeId: string; possibleDescendantNodeId: string },
): boolean {
  const descendants = nodeIdToDescendantNodeIds.get(nodeId);
  return !!descendants && descendants.has(possibleDescendantNodeId);
}

function getNodeIdToDescendantNodeIds(nodeIdToAnswerPathMap: NodeIdToAnswerPathMap): Map<string, Set<string>> {
  const nodeIdToDescendantNodeIds = new Map<string, Set<string>>();

  const answerPathToNodeIdMap = new Map<AnswerPath, string>();
  for (const [nodeId, answerPath] of nodeIdToAnswerPathMap) {
    answerPathToNodeIdMap.set(answerPath, nodeId);
  }

  for (const [nodeId, answerPath] of nodeIdToAnswerPathMap) {
    let ancestor = answerPath.parent;
    while (ancestor) {
      const ancestorNodeId = answerPathToNodeIdMap.get(ancestor);

      if (ancestorNodeId) {
        const descendantSet = nodeIdToDescendantNodeIds.get(ancestorNodeId) ?? new Set<string>();
        descendantSet.add(nodeId);

        if (!nodeIdToDescendantNodeIds.has(ancestorNodeId))
          nodeIdToDescendantNodeIds.set(ancestorNodeId, descendantSet);
      }

      ancestor = ancestor.parent;
    }
  }

  return nodeIdToDescendantNodeIds;
}
