import { EvaluatedValidityQuestionnaire } from './nodeEvaluation/validIf/validity';
import {
  RenderingField,
  RenderingQuestion,
  RenderingQuestionnaire,
  RenderingSection,
  RenderingSectionGroup,
  RenderingSubsection,
  TransitionField,
  TransitionQuestion,
  TransitionSection,
  TransitionSectionGroup,
  TransitionSubsection,
} from './renderingTransforms';
import {
  transformSections,
  transformSubsections,
  transformQuestions,
} from './renderingTransforms/QuestionnaireTransforms';

type UnevaluatedQuestionnaire = EvaluatedValidityQuestionnaire | PartialCompletionQuestionnaire;

type PartialCompletionQuestionnaire = (RenderingSectionGroup & {
  completed?: boolean;
  sections: (RenderingSection & {
    completed?: boolean;
    subsections: (RenderingSubsection & {
      completed?: boolean;
      questions: (RenderingQuestion & {
        completed?: boolean;
        fields: RenderingField[];
      })[];
    })[];
  })[];
})[];

export function setQuestionnaireCompletion(
  questionnaire: UnevaluatedQuestionnaire,
  allFieldsCompleted: boolean,
  loadingFields: boolean,
): RenderingQuestionnaire {
  const withQuestionCompletion = setQuestionsCompletion(
    questionnaire as PartialCompletionQuestionnaire,
    allFieldsCompleted,
    loadingFields,
  );
  const withSubsectionCompletion = setSubsectionsCompletion(withQuestionCompletion as PartialCompletionQuestionnaire);
  const withSectionCompletion = setSectionsCompletion(withSubsectionCompletion as PartialCompletionQuestionnaire);
  const withSectionGroupCompletion = setSectionGroupsCompletion(
    withSectionCompletion as PartialCompletionQuestionnaire,
  );
  return withSectionGroupCompletion;
}

// this function needs to be called first
function setQuestionsCompletion(
  questionnaire: PartialCompletionQuestionnaire,
  allFieldsCompleted: boolean,
  loadingFields: boolean,
): PartialCompletionQuestionnaire {
  transformQuestions(questionnaire, (question: TransitionQuestion) => {
    const visibleFields = question.fields.filter((field: TransitionField) => field.visible);
    if (loadingFields) question.completed = false;
    else {
      question.completed = allFieldsCompleted
        ? true
        : visibleFields.every((field: TransitionField) => field.valid === true);
    }
  });
  return questionnaire;
}

// this function needs to be called after `setQuestionsCompletion()`
function setSubsectionsCompletion(questionnaire: PartialCompletionQuestionnaire): PartialCompletionQuestionnaire {
  transformSubsections(questionnaire, (subsection: TransitionSubsection) => {
    const visibleQuestions = subsection.questions.filter((question: TransitionQuestion) => question.visible);
    subsection.completed = visibleQuestions.every((question: TransitionQuestion) => question.completed);
  });
  return questionnaire;
}

// this function needs to be called after `setSubsectionsCompletion()`
function setSectionsCompletion(questionnaire: PartialCompletionQuestionnaire): PartialCompletionQuestionnaire {
  transformSections(questionnaire, (section: TransitionSection) => {
    const visibleSubsections = section.subsections.filter((subsection: TransitionSubsection) => subsection.visible);
    section.completed = visibleSubsections.every((subsection: TransitionSubsection) => subsection.completed);
  });
  return questionnaire;
}

// this function needs to be called after `setSectionsCompletion()`
function setSectionGroupsCompletion(questionnaire: PartialCompletionQuestionnaire): RenderingQuestionnaire {
  return questionnaire.map((sectionGroup: TransitionSectionGroup) => {
    const visibleSections = sectionGroup.sections.filter((section: TransitionSection) => section.visible);
    sectionGroup.completed = visibleSections.every((section: TransitionSection) => section.completed);
    return sectionGroup;
  });
}
