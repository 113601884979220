import _ from 'lodash';

export default abstract class BaseBuilder<TNodeType> {
  public and(): this {
    return this;
  }

  public abstract build(): TNodeType;

  protected clone(): this {
    return _.cloneDeep(this);
  }
}
