import { NodeId, QuestionnaireBlueprint } from '@breathelife/types';

type OrphanNodeId = string;
type KeyWithNodeId = string;

const blueprintStringPropertyNames: KeyWithNodeId[] = [
  'answerNodeId',
  'repeatableAnswerNodeId',
  'nodeId',
  'targetNodeId',
  'at',
  'product',
  'coverageAmount',
  'useNeedsAnalysisNodeId',
  'fromCollection',
  'repeatableCollectionNodeId',
];
const blueprintArrayPropertyNames: KeyWithNodeId[] = ['firstNonEmptyValueAt'];

export const generateOrphanNodeIdsFromBlueprint = (blueprint: QuestionnaireBlueprint): Set<OrphanNodeId> => {
  const allNodeIds: Set<NodeId> = new Set(searchJSONForNodeId(blueprint));

  const nodeIdsUsedInBlueprint: Set<NodeId | undefined> = findNodeIdsAssignedInBlueprint(blueprint);

  const setOrphanNodeIds: Set<NodeId> = new Set([]);

  for (const nodeId of allNodeIds) {
    if (nodeIdsUsedInBlueprint.has(nodeId) === false) {
      setOrphanNodeIds.add(nodeId);
    }
  }

  return setOrphanNodeIds;
};

function searchJSONForNodeId(currentScope: Record<any, any>): NodeId[] {
  const nodeIdsInChildren: NodeId[][] = Object.keys(currentScope).map((property): NodeId[] => {
    const propertyValue = currentScope[property];

    if (typeof propertyValue !== 'object') {
      // The thing is not an array nor an object.
      return [];
    }

    // Handling array property
    if (propertyValue.length) {
      return propertyValue.map((e: any) => searchJSONForNodeId(e)).flat();
    }

    // Handling object property
    return searchJSONForNodeId(propertyValue);
  });

  // Handling nodeIdOnCurrentScope
  const currentScopeNodeIds: NodeId[] = [];
  for (const name of blueprintStringPropertyNames) {
    const propertyValue = currentScope[name];
    if (propertyValue && typeof propertyValue === 'string') {
      currentScopeNodeIds.push(propertyValue);
    }
  }

  for (const name of blueprintArrayPropertyNames) {
    const propertyValues = currentScope[name];
    if (propertyValues && typeof propertyValues.length) {
      for (const item of propertyValues) {
        currentScopeNodeIds.push(item);
      }
    }
  }

  return currentScopeNodeIds.concat(nodeIdsInChildren.flat());
}

function findNodeIdsAssignedInBlueprint(blueprint: QuestionnaireBlueprint): Set<string> {
  const fieldOrRepeatableParentNodeIds = new Set<string>();

  Object.values(blueprint.sectionGroupBlueprints).forEach((sectionGroup) => {
    if (sectionGroup.repeatable?.repeatableAnswerNodeId) {
      fieldOrRepeatableParentNodeIds.add(sectionGroup.repeatable.repeatableAnswerNodeId);
    }
  });

  for (const section of blueprint.sectionBlueprints) {
    for (const subsection of section.subsections) {
      for (const question of subsection.questions) {
        if (question.repeatable?.repeatableAnswerNodeId) {
          fieldOrRepeatableParentNodeIds.add(question.repeatable.repeatableAnswerNodeId);
        }

        for (const field of question.fields) {
          if (field.answerNodeId) {
            fieldOrRepeatableParentNodeIds.add(field.answerNodeId);
          }
        }
      }
    }
  }
  return fieldOrRepeatableParentNodeIds;
}
