import _ from 'lodash';

import {
  Language,
  RenderingType,
  SubsectionVariant,
  Answers,
  Timezone,
  IAnswerResolver,
  ApplicationContext,
} from '@breathelife/types';

import { setQuestionnaireCompletion } from '../completion';
import { TextGetter } from '../locale';
import { transformItemsVisibility } from '../nodeEvaluation';
import { transformItemsValidity } from '../nodeEvaluation/validIf/validity';
import { QuestionnaireDefinition } from '../structure';
import { setFieldValues, transformFields, transformQuestions, transformSubsections } from './QuestionnaireTransforms';
import { RenderingQuestionnaire } from './RenderingQuestionnaire';
import { asExpandedQuestionnaire } from './RepeatableExpansion';
import { isRenderingRepeatedQuestion } from './Structure';
import { ActiveSectionId } from './navigation';
import { populateDynamicOptions } from './populateDynamicOptions';
import { setValidationFields, UnevaluatedValidationQuestionnaire } from './validationTransforms';
import { populateApplicationContextSelectOptions } from './populateApplicationContextSelectOptions';

export type RenderingQuestionnaireGeneratorConfig = {
  logUnexpectedAnswers: boolean;
  logValidationErrors: boolean;
};

export const DEFAULT_CONFIG: RenderingQuestionnaireGeneratorConfig = {
  logUnexpectedAnswers: false,
  logValidationErrors: false,
};

export class OldRenderingQuestionnaireGenerator {
  private readonly questionnaire: QuestionnaireDefinition;
  private readonly language: Language;
  private readonly text: (value: string, params: any) => string;
  private readonly renderingType: RenderingType;
  private readonly config: RenderingQuestionnaireGeneratorConfig;
  private readonly answersResolver: IAnswerResolver;
  private readonly timezone: Timezone;
  private readonly applicationContext: ApplicationContext;

  constructor(
    questionnaire: QuestionnaireDefinition,
    answersResolver: IAnswerResolver,
    text: TextGetter,
    language: Language,
    renderingType: RenderingType = RenderingType.web,
    config: RenderingQuestionnaireGeneratorConfig = DEFAULT_CONFIG,
    timezone: Timezone,
    applicationContext: ApplicationContext = {},
  ) {
    this.questionnaire = questionnaire;
    this.answersResolver = answersResolver;
    this.text = text;
    this.renderingType = renderingType;
    this.config = config;
    this.timezone = timezone;
    this.applicationContext = applicationContext;
    this.language = language;
  }

  public generate(
    answers: Answers,
    shouldValidateAllAnswers: boolean,
    loadingFields?: boolean,
    allFieldsCompleted?: boolean,
    activeSectionId?: ActiveSectionId,
  ): RenderingQuestionnaire {
    const clonedQuestionnaire = _.cloneDeep(this.questionnaire);

    // Expands repeatable questions into the appropriate number of repeated questions
    const repeatedFieldsQuestionnaire = asExpandedQuestionnaire(clonedQuestionnaire, answers, this.answersResolver);

    // Populate computed option fields with values
    const withDynamicOptionsQuestionnaire = populateDynamicOptions(
      repeatedFieldsQuestionnaire,
      answers,
      this.answersResolver,
    );

    // Populate select options from application contexts
    const withApplicationContextSelectOptions = populateApplicationContextSelectOptions(
      withDynamicOptionsQuestionnaire,
      this.applicationContext,
      answers,
      this.language,
    );

    // Sets the `visible` property of all items based on `visibleIf` conditions
    const visibilityEvaluatedQuestionnaire = transformItemsVisibility(
      withApplicationContextSelectOptions,
      this.answersResolver,
      answers,
      this.renderingType,
      this.timezone,
    );

    // Sets the `value` property of all items based on the provided answers
    setFieldValues(visibilityEvaluatedQuestionnaire, answers, this.answersResolver);

    if (!allFieldsCompleted) {
      // Checks answers against yup schema and set `validationErrorMessage` attribute as appropriate
      setValidationFields(
        visibilityEvaluatedQuestionnaire as UnevaluatedValidationQuestionnaire,
        shouldValidateAllAnswers,
        this.text,
        this.config.logValidationErrors,
        this.timezone,
        activeSectionId,
      );
    }

    // Validity must be computed after visibility to avoid evaluating validity for hidden fields.
    const ruleValidityEvaluatedQuestionnaire = transformItemsValidity(
      visibilityEvaluatedQuestionnaire,
      answers,
      this.answersResolver,
      this.text,
      {
        shouldValidateAllAnswers,
      },
      this.timezone,
    );

    const completionEvaluatedQuestionnaire = setQuestionnaireCompletion(
      ruleValidityEvaluatedQuestionnaire,
      allFieldsCompleted || false,
      loadingFields || false,
    );

    transformSubsections(completionEvaluatedQuestionnaire, (subsection) => {
      if (!subsection.variant) {
        subsection.variant = SubsectionVariant.form;
      }
      return subsection;
    });

    transformQuestions(completionEvaluatedQuestionnaire, (question) => {
      if (isRenderingRepeatedQuestion(question)) {
        question.showRemoveQuestionButton = question.showRemoveQuestionButton && !allFieldsCompleted;
        question.showAddQuestionButton = question.showAddQuestionButton && !allFieldsCompleted;
      }
    });

    transformFields(completionEvaluatedQuestionnaire, (field) => {
      field.disabled = allFieldsCompleted || field.disabled;
    });

    return completionEvaluatedQuestionnaire;
  }
}
