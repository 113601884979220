import { isSectionGroupKey, QuestionnaireBlueprint } from '@breathelife/types';

export const translateNodeIdToBlueprintId = (blueprint: QuestionnaireBlueprint): Record<string, string> => {
  const nodeIdToBlueprintId: Record<string, string> = {};

  Object.keys(blueprint.sectionGroupBlueprints).forEach((key: string) => {
    if (!isSectionGroupKey(key)) {
      return;
    }
    const sectionGroup = blueprint.sectionGroupBlueprints[key];

    if (!sectionGroup) {
      return;
    }

    const repeatableAnswerNodeId = sectionGroup.repeatable?.repeatableAnswerNodeId;
    if (repeatableAnswerNodeId && !nodeIdToBlueprintId[repeatableAnswerNodeId]) {
      nodeIdToBlueprintId[repeatableAnswerNodeId] = sectionGroup.id;
    }
  });

  // We grab the first one we find, it does not matter, the linkedBlueprintsIds will propagate the answers.
  blueprint.sectionBlueprints.forEach((section) => {
    section.subsections.forEach((subsection) => {
      subsection.questions.forEach((question) => {
        const repeatableAnswerNodeId = question.repeatable?.repeatableAnswerNodeId;
        if (repeatableAnswerNodeId && !nodeIdToBlueprintId[repeatableAnswerNodeId]) {
          nodeIdToBlueprintId[repeatableAnswerNodeId] = question.id;
        }
        question.fields.forEach((field) => {
          if (nodeIdToBlueprintId[field.answerNodeId]) {
            return;
          }

          nodeIdToBlueprintId[field.answerNodeId] = field.id;
        });
      });
    });
  });

  return nodeIdToBlueprintId;
};
