import _ from 'lodash';

import {
  QuestionnaireScreenConfig,
  QuestionnaireScreenDefaultFieldName,
  QuestionnaireScreenFieldConfig,
} from '@breathelife/types';

import QuestionnaireVisitor from '../QuestionnaireVisitor';
import { Field, QuestionnaireDefinition } from '../structure';
import { Validations } from '../validations';
import { Localized } from '../locale';

export class QuestionnaireScreenExtenderVisitor extends QuestionnaireVisitor {
  private screenConfig: QuestionnaireScreenConfig;

  constructor(screenConfig: QuestionnaireScreenConfig) {
    super();
    this.screenConfig = screenConfig;
  }

  public visitQuestionnaire(questionnaire: Localized<QuestionnaireDefinition>): {
    output: void;
    questionnaire: Localized<QuestionnaireDefinition>;
  } {
    const clonedQuestionnaire: Localized<QuestionnaireDefinition> = _.cloneDeep(questionnaire);
    super.visitQuestionnaire(clonedQuestionnaire);

    return { questionnaire: clonedQuestionnaire, output: undefined };
  }

  public visitField(field: Localized<Field>): void {
    const fieldConfig: QuestionnaireScreenFieldConfig =
      this.screenConfig?.[field.type] || this.screenConfig[QuestionnaireScreenDefaultFieldName];

    if (fieldConfig?.optional !== undefined) {
      field.optional = fieldConfig.optional ? true : undefined;
    }

    if (fieldConfig?.validationType !== undefined) {
      field.validation.type = fieldConfig.validationType as Validations;
    }

    if (fieldConfig?.skipValidityConditions !== undefined && fieldConfig?.skipValidityConditions) {
      field.validIf = [];
    }
  }
}

export function extendQuestionnaireWithScreen(
  questionnaire: Localized<QuestionnaireDefinition>,
  screenConfig: QuestionnaireScreenConfig,
): Localized<QuestionnaireDefinition> {
  const visitor = new QuestionnaireScreenExtenderVisitor(screenConfig);
  const { questionnaire: extendedQuestionnaire } = visitor.visitQuestionnaire(questionnaire);

  return extendedQuestionnaire;
}
