import { v4 as uuid } from 'uuid';

import { FieldBlueprint, QuestionBlueprint, SectionBlueprint, SubsectionBlueprint } from '@breathelife/types';

export function switchToCustomPartName<T extends { partName: string; isCustom?: boolean; id: string }>(
  blueprint: T,
): T {
  return { ...blueprint, partName: `custom-${uuid()}`, id: uuid(), isCustom: true };
}

export function toCustomField(blueprint: FieldBlueprint): FieldBlueprint {
  return switchToCustomPartName(blueprint);
}

export function toCustomFieldGroup(blueprint: QuestionBlueprint): QuestionBlueprint {
  const customClonedBlueprint = switchToCustomPartName(blueprint);

  customClonedBlueprint.fields = customClonedBlueprint.fields.map(toCustomField);

  return customClonedBlueprint;
}

export function toCustomSubsection(blueprint: SubsectionBlueprint): SubsectionBlueprint {
  const customClonedBlueprint = switchToCustomPartName(blueprint);

  customClonedBlueprint.questions = customClonedBlueprint.questions.map(toCustomFieldGroup);

  return customClonedBlueprint;
}

export function toCustomSection(blueprint: SectionBlueprint): SectionBlueprint {
  const customClonedBlueprint = switchToCustomPartName(blueprint);

  customClonedBlueprint.subsections = customClonedBlueprint.subsections.map(toCustomSubsection);

  return customClonedBlueprint;
}
