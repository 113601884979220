import { ParticipantRoles, FieldTypes } from '@breathelife/types';

import { DynamicOptions } from '../../structure';
import {
  ButtonFieldBuilder,
  ConsentFieldBuilder,
  DateFieldBuilder,
  DynamicOptionFieldBuilder,
  InformationFieldBuilder,
  MoneyFieldBuilder,
  NumberFieldBuilder,
  OptionFieldBuilder,
  PhoneFieldBuilder,
  TextFieldBuilder,
  YearMonthFieldBuilder,
  SignatureFieldBuilder,
} from './FieldBuilder';
import QuestionBuilder from './QuestionBuilder';
import QuestionnaireBuilder from './QuestionnaireBuilder';
import SectionBuilder from './SectionBuilder';
import SectionGroupBuilder from './SectionGroupBuilder';
import { SeedProviders } from './SeedProviders';
import SubsectionBuilder from './SubsectionBuilder';

export type QuestionnaireBuilderFunctions = {
  aQuestionnaire: () => QuestionnaireBuilder;
  aSectionGroup: () => SectionGroupBuilder;
  aRepeatableSectionGroup: ({ nodeId }: { nodeId: string }) => SectionGroupBuilder;
  aSection: () => SectionBuilder;
  aStep: () => SectionBuilder;
  aSubsection: () => SubsectionBuilder;
  aQuestion: () => QuestionBuilder;
  aRepeatableQuestion: ({ nodeId }: { nodeId: string }) => QuestionBuilder;
  aTextField: () => TextFieldBuilder;
  aDateField: () => DateFieldBuilder;
  aYearMonthField: () => YearMonthFieldBuilder;
  aConsentField: () => ConsentFieldBuilder;
  aNumberField: () => NumberFieldBuilder;
  aPhoneField: () => PhoneFieldBuilder;
  aMoneyField: () => MoneyFieldBuilder;
  aDropdownField: () => OptionFieldBuilder;
  aRadioField: () => OptionFieldBuilder;
  aCheckboxGroupField: () => OptionFieldBuilder;
  aDynamicDropdownField: (dynamicOptions: DynamicOptions) => DynamicOptionFieldBuilder;
  anInformationField: () => InformationFieldBuilder;
  aButtonField: () => ButtonFieldBuilder;
  aSignatureField: (participantRole: ParticipantRoles) => SignatureFieldBuilder;
};

export function generateQuestionnaireBuilders(seedProviders: SeedProviders): QuestionnaireBuilderFunctions {
  return {
    aQuestionnaire(): QuestionnaireBuilder {
      return new QuestionnaireBuilder(seedProviders);
    },
    aSectionGroup(): SectionGroupBuilder {
      return new SectionGroupBuilder(seedProviders);
    },
    aRepeatableSectionGroup({ nodeId }: { nodeId: string }): SectionGroupBuilder {
      return new SectionGroupBuilder(seedProviders).makeRepeatable({ nodeId });
    },

    aSection(): SectionBuilder {
      return new SectionBuilder(seedProviders);
    },
    aStep(): SectionBuilder {
      return new SectionBuilder(seedProviders);
    },

    aSubsection(): SubsectionBuilder {
      return new SubsectionBuilder(seedProviders);
    },

    aQuestion(): QuestionBuilder {
      return new QuestionBuilder(seedProviders);
    },

    aRepeatableQuestion({ nodeId }: { nodeId: string }): QuestionBuilder {
      return new QuestionBuilder(seedProviders).makeRepeatable({ nodeId });
    },

    aTextField(): TextFieldBuilder {
      return new TextFieldBuilder(seedProviders);
    },

    aDateField(): DateFieldBuilder {
      return new DateFieldBuilder(seedProviders);
    },
    aYearMonthField(): YearMonthFieldBuilder {
      return new YearMonthFieldBuilder(seedProviders);
    },

    // TODO @questionnaireBuilder Should this be type AgreeField?
    aConsentField(): ConsentFieldBuilder {
      return new ConsentFieldBuilder(seedProviders);
    },

    aNumberField(): NumberFieldBuilder {
      return new NumberFieldBuilder(seedProviders);
    },

    aPhoneField(): PhoneFieldBuilder {
      return new PhoneFieldBuilder(seedProviders);
    },

    aMoneyField(): MoneyFieldBuilder {
      return new MoneyFieldBuilder(seedProviders);
    },

    aDropdownField(): OptionFieldBuilder {
      return new OptionFieldBuilder(seedProviders);
    },

    aRadioField(): OptionFieldBuilder {
      return new OptionFieldBuilder(seedProviders).withType(FieldTypes.radio);
    },

    aCheckboxGroupField(): OptionFieldBuilder {
      return new OptionFieldBuilder(seedProviders).withType(FieldTypes.checkboxGroup);
    },

    aDynamicDropdownField(dynamicOptions: DynamicOptions): DynamicOptionFieldBuilder {
      return new DynamicOptionFieldBuilder(seedProviders, dynamicOptions).withType(FieldTypes.dropdown);
    },

    anInformationField(): InformationFieldBuilder {
      return new InformationFieldBuilder(seedProviders);
    },

    aButtonField(): ButtonFieldBuilder {
      return new ButtonFieldBuilder(seedProviders);
    },

    aSignatureField(participantRole: ParticipantRoles): SignatureFieldBuilder {
      return new SignatureFieldBuilder(seedProviders, participantRole);
    },
  };
}
