import _ from 'lodash';

import { Conditions, Localizable } from '@breathelife/types';

import { RepeatableSectionGroup, Section, SectionGroup } from '../../structure';
import { validateSectionGroup } from '../../structureValidations';
import FieldBuilder from './FieldBuilder';
import NodeBuilder from './NodeBuilder';
import QuestionBuilder from './QuestionBuilder';
import SectionBuilder from './SectionBuilder';
import { SeedProviders } from './SeedProviders';
import SubsectionBuilder from './SubsectionBuilder';

export default class SectionGroupBuilder extends NodeBuilder<SectionGroup> {
  private readonly seedProviders: SeedProviders;

  public constructor(seedProviders: SeedProviders) {
    super(seedProviders.forSectionGroup());
    this.seedProviders = seedProviders;
  }

  public with(nodeBuilder: SectionBuilder | SubsectionBuilder | QuestionBuilder | FieldBuilder): this {
    if (nodeBuilder instanceof SectionBuilder) {
      return this.withSections(nodeBuilder);
    }
    return this.withSections(this.childBuilder().with(nodeBuilder));
  }

  public withSections(sectionBuilders?: SectionBuilder[] | SectionBuilder): this {
    const builders = sectionBuilders ? _.castArray(sectionBuilders) : [];

    if (builders.length === 0) {
      builders.push(this.childBuilder());
    }

    if (builders.length === 0) {
      builders.push(new SectionBuilder(this.seedProviders));
    }

    const sections = builders.map((builder) => builder.build());
    if (!this.node.sections) {
      this.node.sections = [];
    }
    this.addSections(sections);

    return this.clone();
  }

  public withSubsections(subsectionBuilders?: SubsectionBuilder[] | SubsectionBuilder): this {
    this.addSections(this.childBuilder().withSubsections(subsectionBuilders).build());

    return this.clone();
  }

  public withQuestions(questionBuilders?: QuestionBuilder[] | QuestionBuilder): this {
    this.addSections(this.childBuilder().withQuestions(questionBuilders).build());

    return this.clone();
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    this.addSections(this.childBuilder().withFields(fieldBuilders).build());

    return this.clone();
  }

  private addSections(sections: Section | Section[]): void {
    if (!this.node.sections) this.node.sections = [];

    const sectionList = _.castArray(sections);
    this.node.sections.push(...sectionList);
  }

  private childBuilder(): SectionBuilder {
    return new SectionBuilder(this.seedProviders);
  }

  public withId(id: string): this {
    this.node.id = id;

    return this.clone();
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public withProperties(properties: Partial<SectionGroup> | Partial<RepeatableSectionGroup>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public visibleIf(condition: Conditions): this {
    this.node.visibleIf = condition;

    return this.clone();
  }

  public makeRepeatable({ nodeId }: { nodeId: string }): this {
    this.node.options = {
      repeatable: true,
      minRepetitions: 1,
      maxRepetitions: 3,
    };

    const repeatableSectionGroup = this.node as RepeatableSectionGroup;
    repeatableSectionGroup.nodeId = nodeId;

    return this.clone();
  }

  public validate(sectionGroup: SectionGroup): sectionGroup is SectionGroup {
    return validateSectionGroup(sectionGroup);
  }
}
