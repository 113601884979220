import { RepeatedAnswers } from '@breathelife/types';

export function orderByRepeatedIndex<T extends string>(repeatedAnswers: {
  [surrogateId: string]: RepeatedAnswers<T>;
}): [string, RepeatedAnswers<T>][] {
  const ordered = Object.entries(repeatedAnswers).sort(
    ([, { repeatedIndex: repeatedIndexA }], [, { repeatedIndex: repeatedIndexB }]) =>
      repeatedIndexA > repeatedIndexB ? 1 : -1,
  );
  return ordered;
}
