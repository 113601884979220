import { Language, SelectOptionBlueprint } from '@breathelife/types';
import { SelectOption } from '../structure';

export function sortOptionsAlphabetically(
  options: SelectOption[] | SelectOptionBlueprint[],
  language: Language,
): SelectOption[] | SelectOptionBlueprint[] {
  return options.sort((a, b) => {
    let textA;
    let textB;
    if (typeof a.text === 'string') {
      // SelectOption[]
      textA = a.text;
      textB = b.text;
    } else {
      // SelectOptionBlueprint[]
      textA = a.text[language];
      textB = b.text[language];
    }
    return (textA as string).localeCompare(textB as string, language, {
      numeric: true,
    });
  });
}
