import { failure, Result, success } from '@breathelife/result';
import {
  IAnswerResolver,
  RepeatedAnswersBySurrogateId,
  Answers,
  QuestionnaireBlueprint,
  NodeInstance,
  SurrogateId,
  InstanceScope,
  InstanceIndex,
} from '@breathelife/types';

import { BlueprintIdAnswersResolver } from './BlueprintIdAnswersResolver';
import { generateOrphanNodeIdsFromBlueprint } from './generateOrphanNodeIdsFromBlueprint';
import { translateNodeIdToBlueprintId } from './translateNodeIdToBlueprintId';

type TranslationError = { tag: 'TranslationError'; nodeId: string };

type Error = TranslationError;

export class TranslationAnswersResolver implements IAnswerResolver {
  private readonly answerResolver: IAnswerResolver;
  private readonly orphanNodeIds: Set<string>;
  private readonly nodeIdToBlueprintIdMap: Record<string, string>;

  constructor(blueprint: QuestionnaireBlueprint) {
    this.answerResolver = BlueprintIdAnswersResolver.from(blueprint);
    this.nodeIdToBlueprintIdMap = translateNodeIdToBlueprintId(blueprint);
    this.orphanNodeIds = generateOrphanNodeIdsFromBlueprint(blueprint);
  }
  setAnswers(
    items: { id: string; value: unknown }[],
    answers: Answers,
    scope?: InstanceScope | undefined,
    answersForPath?: Answers | undefined,
  ): void {
    for (const item of items) {
      this.setAnswer(item.value, answers, item.id, scope, answersForPath);
    }
  }

  getInstanceId(answers: Answers, nodeId: string, scope: InstanceScope): Result<string, SurrogateId | undefined> {
    if (this.orphanNodeIds.has(nodeId)) {
      return failure(`Won't get the surrogate id for "${nodeId} because it's an orphan nodeId.`);
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return failure(`Could not find a valid blueprint id for the "${nodeId}" while trying to get the surrogate id.`);
    }

    let translatedCollectionIdentifiers: InstanceScope = {};

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return failure(
          `Could not translate the id "${result.error.nodeId}" from the collection instance identifiers while trying to get the surrogate id.`,
        );
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.answerResolver.getInstanceId(answers, blueprintId, translatedCollectionIdentifiers);
  }

  setInstanceId(
    answers: Answers,
    nodeId: string,
    scope: InstanceScope,
    forceInstanceId?: string | undefined,
    idIsALeaf?: boolean,
  ): Result<string, SurrogateId> {
    if (this.orphanNodeIds.has(nodeId)) {
      return failure(`Won't set the surrogate id for "${nodeId} because it's an orphan nodeId.`);
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return failure(`Could not find a valid blueprint id for the "${nodeId}" while trying to set the surrogate id.`);
    }

    let translatedCollectionIdentifiers: InstanceScope = {};

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return failure(
          `Could not translate the id "${result.error.nodeId}" from the collection instance identifiers while trying to set the surrogate id.`,
        );
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.answerResolver.setInstanceId(
      answers,
      blueprintId,
      translatedCollectionIdentifiers,
      forceInstanceId,
      idIsALeaf,
    );
  }

  public knowsId(nodeId: string): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return false;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return false;
    }

    return this.answerResolver.knowsId(blueprintId);
  }

  private translateInstanceScope(scope: InstanceScope): Result<Error, InstanceScope> {
    const translated: InstanceScope = {};

    const keys = Object.keys(scope);

    for (const nodeId of keys) {
      const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

      if (!blueprintId) {
        return failure({ tag: 'TranslationError', nodeId });
      }

      translated[blueprintId] = scope[nodeId];
    }

    return success(translated);
  }

  public getAnswer(
    answers: Answers,
    nodeId: string,
    scope?: InstanceScope,
    answersForPath?: Answers,
    skipLeafIdentifier?: boolean,
  ): any | undefined {
    if (this.orphanNodeIds.has(nodeId)) {
      return undefined;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return undefined;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.answerResolver.getAnswer(
      answers,
      blueprintId,
      translatedCollectionIdentifiers,
      answersForPath,
      skipLeafIdentifier,
    );
  }

  public getCollection(answers: Answers, nodeId: string, scope?: InstanceScope): any[] | undefined {
    if (this.orphanNodeIds.has(nodeId)) {
      return undefined;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return undefined;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.answerResolver.getCollection(answers, blueprintId, translatedCollectionIdentifiers);
  }

  public isCollection(nodeId: string): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return false;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (blueprintId) {
      return this.answerResolver.isCollection(blueprintId);
    }

    throw new Error(`Could not find a blueprintId corresponding to the nodeId ${nodeId}`);
  }

  public getRepeatedAnswers<T extends string>(
    answers: Answers,
    collectionNodeId: string,
    ids: T[],
    scope: InstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    for (const id of ids) {
      if (this.orphanNodeIds.has(id)) {
        return undefined;
      }
    }

    const collectionBlueprintId = this.nodeIdToBlueprintIdMap[collectionNodeId];

    const translatedCollectionIdentifiers = this.translateInstanceScope(scope);

    const blueprintIdToNodeId: Record<string, string> = ids.reduce((acc: Record<string, string>, nodeId: string) => {
      acc[this.nodeIdToBlueprintIdMap[nodeId]] = nodeId;
      return acc;
    }, {});

    const translatedIds = Object.keys(blueprintIdToNodeId);

    if (
      !collectionBlueprintId ||
      !translatedCollectionIdentifiers.success ||
      translatedIds.some((e) => e === undefined)
    ) {
      return undefined;
    }

    const blueprintIdRepeatedAnswers = this.answerResolver.getRepeatedAnswers(
      answers,
      collectionBlueprintId,
      translatedIds,
      translatedCollectionIdentifiers.value,
    );

    if (!blueprintIdRepeatedAnswers) {
      return undefined;
    }

    const nodeIdRepeatedAnswers: RepeatedAnswersBySurrogateId<T> = {};
    Object.keys(blueprintIdRepeatedAnswers).forEach((surrogateId: string) => {
      const current = blueprintIdRepeatedAnswers[surrogateId];

      const newAnswers: Partial<Record<string, any>> = {};
      Object.keys(current.answersByNodeId).forEach((blueprintId: string) => {
        const nodeId = blueprintIdToNodeId[blueprintId];
        newAnswers[nodeId] = current.answersByNodeId[blueprintId];
      });

      nodeIdRepeatedAnswers[surrogateId] = {
        answersByNodeId: newAnswers,
        repeatedIndex: current.repeatedIndex,
      };
    });

    return nodeIdRepeatedAnswers;
  }

  public getRepetitionCount(answers: Answers, collectionNodeId: string, scope: InstanceScope): number | undefined {
    if (this.orphanNodeIds.has(collectionNodeId)) {
      return undefined;
    }

    const collectionBlueprintId = this.nodeIdToBlueprintIdMap[collectionNodeId];

    const translatedCollectionIdentifiers = this.translateInstanceScope(scope);

    if (collectionBlueprintId && translatedCollectionIdentifiers.success) {
      return this.answerResolver.getRepetitionCount(
        answers,
        collectionBlueprintId,
        translatedCollectionIdentifiers.value,
      );
    }
  }

  public setAnswer(
    value: unknown,
    answers: Answers,
    nodeId: string,
    scope?: InstanceScope,
    answersForPath?: Answers,
  ): void {
    if (this.orphanNodeIds.has(nodeId)) {
      return;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return undefined;
      }
      translatedCollectionIdentifiers = result.value;
    }

    this.answerResolver.setAnswer(value, answers, blueprintId, translatedCollectionIdentifiers, answersForPath);
  }

  public unsetAnswer(answers: Answers, nodeId: string, scope?: InstanceScope, answersForPath?: Answers): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return false;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return false;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.answerResolver.unsetAnswer(answers, blueprintId, translatedCollectionIdentifiers, answersForPath);
  }

  unsetAnswers(answers: Answers, nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, scope } = nodeInstance;
      const wasRemoved = this.unsetAnswer(answers, id, scope);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  public unsetAnswerSelectOptionId(answers: Answers, nodeId: string, optionId: string, scope?: InstanceScope): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return false;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return false;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.answerResolver.unsetAnswerSelectOptionId(
      answers,
      blueprintId,
      optionId,
      translatedCollectionIdentifiers,
    );
  }

  public withCollectionIdentifier(
    identifiers: InstanceScope,
    collectionNodeId: string,
    collectionIdentifier: InstanceIndex,
  ): InstanceScope {
    const translatedCollectionNodeId = this.nodeIdToBlueprintIdMap[collectionNodeId];
    const translatedIdentifiers = this.translateInstanceScope(identifiers);

    const blueprintIdToNodeId: Record<string, string> = [...Object.keys(identifiers), collectionNodeId].reduce(
      (acc: Record<string, string>, nodeId: string) => {
        acc[this.nodeIdToBlueprintIdMap[nodeId]] = nodeId;
        return acc;
      },
      {},
    );

    if (!translatedCollectionNodeId || translatedIdentifiers.success === false) {
      throw new Error(`Could not find a blueprintId corresponding to the nodeId ${collectionNodeId}`);
    }

    const blueprintIdCollectionIdentifiers = this.answerResolver.withCollectionIdentifier(
      translatedIdentifiers.value,
      translatedCollectionNodeId,
      collectionIdentifier,
    );

    const nodeIdCollectionIdentifiers: Record<string, number> = {};
    Object.keys(blueprintIdCollectionIdentifiers).forEach((blueprintId) => {
      nodeIdCollectionIdentifiers[blueprintIdToNodeId[blueprintId]] = blueprintIdCollectionIdentifiers[blueprintId];
    });

    return nodeIdCollectionIdentifiers;
  }

  public removeUndefinedAnswersFromCollection(nodeId: string, answers: Answers, scope?: InstanceScope): boolean {
    if (this.orphanNodeIds.has(nodeId)) {
      return true;
    }

    const blueprintId = this.nodeIdToBlueprintIdMap[nodeId];

    if (!blueprintId) {
      return false;
    }

    let translatedCollectionIdentifiers: InstanceScope | undefined;

    if (scope) {
      const result = this.translateInstanceScope(scope);
      if (result.success === false) {
        return false;
      }
      translatedCollectionIdentifiers = result.value;
    }

    return this.answerResolver.removeUndefinedAnswersFromCollection(
      blueprintId,
      answers,
      translatedCollectionIdentifiers,
    );
  }
}
