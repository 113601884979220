import _ from 'lodash';

import { Conditions, Localizable } from '@breathelife/types';

import { Section, Subsection } from '../../structure';
import { validateSection } from '../../structureValidations';
import FieldBuilder from './FieldBuilder';
import NodeBuilder from './NodeBuilder';
import QuestionBuilder from './QuestionBuilder';
import { SeedProviders } from './SeedProviders';
import SubsectionBuilder from './SubsectionBuilder';

export default class SectionBuilder extends NodeBuilder<Section> {
  private readonly seedProviders: SeedProviders;

  public constructor(seedProviders: SeedProviders) {
    super(seedProviders.forSection());
    this.seedProviders = seedProviders;
  }

  public with(nodeBuilder: SubsectionBuilder | QuestionBuilder | FieldBuilder): this {
    if (nodeBuilder instanceof SubsectionBuilder) {
      return this.withSubsections(nodeBuilder);
    }
    return this.withSubsections(this.childBuilder().with(nodeBuilder));
  }

  public withSubsections(subsectionBuilders?: SubsectionBuilder[] | SubsectionBuilder): this {
    const builders = subsectionBuilders ? _.castArray(subsectionBuilders) : [];

    if (builders.length === 0) {
      builders.push(this.childBuilder());
    }

    const subsections = builders.map((builder) => builder.build());
    this.addSubsections(subsections);

    return this.clone();
  }

  public withQuestions(questionBuilders?: QuestionBuilder[] | QuestionBuilder): this {
    this.addSubsections(this.childBuilder().withQuestions(questionBuilders).build());

    return this.clone();
  }

  public withFields(fieldBuilders?: FieldBuilder[] | FieldBuilder): this {
    this.addSubsections(this.childBuilder().withFields(fieldBuilders).build());

    return this.clone();
  }

  private addSubsections(subsections: Subsection | Subsection[]): void {
    if (!this.node.subsections) this.node.subsections = [];

    const subsectionList = _.castArray(subsections);
    this.node.subsections.push(...subsectionList);
  }

  private childBuilder(): SubsectionBuilder {
    return new SubsectionBuilder(this.seedProviders);
  }

  public withProperties(properties: Partial<Section>): this {
    Object.assign(this.node, properties);
    return this.clone();
  }

  public withId(id: string): this {
    this.node.id = id;
    return this.clone();
  }

  public withTitle(title: Localizable): this {
    this.node.title = title;
    return this.clone();
  }

  public visibleIf(condition: Conditions): this {
    this.node.visibleIf = condition;

    return this.clone();
  }

  public validate(section: Section): section is Section {
    return validateSection(section);
  }
}
