import { Language } from '@breathelife/types';

import { isDropdownOptionField, QuestionnaireDefinition } from '../structure';

const regexSomethingIsNotAlphaWithSomeSymbols = /[^a-zA-Z-\s]/;

export function orderSelectOptions(
  questionnaire: QuestionnaireDefinition,
  language: Language,
): QuestionnaireDefinition {
  for (const sectionGroup of questionnaire) {
    for (const section of sectionGroup.sections) {
      for (const subsection of section.subsections) {
        for (const question of subsection.questions) {
          for (const field of question.fields) {
            if (isDropdownOptionField(field)) {
              (field as any).options.sort((a: any, b: any) => {
                const aIndex: number = a.orderingIndex === undefined ? 1 : a.orderingIndex;
                const bIndex: number = b.orderingIndex === undefined ? 1 : b.orderingIndex;

                if (aIndex === bIndex) {
                  if (
                    a.text.match(regexSomethingIsNotAlphaWithSomeSymbols) ||
                    b.text.match(regexSomethingIsNotAlphaWithSomeSymbols)
                  ) {
                    return a.text.localeCompare(b.text, language, {
                      numeric: true,
                    });
                  } else {
                    return a.text > b.text ? 0 : -1;
                  }
                }

                return aIndex > bIndex ? 0 : -1;
              });
            }
          }
        }
      }
    }
  }
  return questionnaire;
}
