import {
  InstanceScope,
  RepeatedAnswersBySurrogateId,
  Answers,
  QuestionnaireBlueprint,
  InstanceIndex,
  VersionedAnswers,
} from '@breathelife/types';

import { AnswerPath } from './AnswerPath';
import { NodeIdAnswersResolver } from './NodeIdAnswersResolver';
import { TranslationAnswersResolver } from './TranslationAnswersResolver';

export class BothAnswersResolver {
  private readonly answerResolverV1: NodeIdAnswersResolver;
  private readonly answerResolverV2: TranslationAnswersResolver;

  private versionedAnswers: VersionedAnswers;

  constructor(
    versionedAnswers: VersionedAnswers,
    nodeIdToAnswerPath: Map<string, AnswerPath>,
    blueprint: QuestionnaireBlueprint,
  ) {
    this.versionedAnswers = versionedAnswers;
    this.answerResolverV1 = new NodeIdAnswersResolver(nodeIdToAnswerPath);
    this.answerResolverV2 = new TranslationAnswersResolver(blueprint);
  }

  public static buildReferencePathKey(value: string | number): string {
    return this.buildReferencePathKey(value);
  }

  public knowsId(id: string): boolean {
    return this.answerResolverV1.knowsId(id);
  }

  public getAnswer(
    id: string,
    scope?: InstanceScope,
    answersForPath?: Answers,
    skipLeafIdentifier?: boolean,
  ): any | undefined {
    return this.answerResolverV1.getAnswer(this.versionedAnswers.v1, id, scope, answersForPath, skipLeafIdentifier);
  }

  public getCollection(id: string, scope?: InstanceScope): any[] | undefined {
    return this.answerResolverV1.getCollection(this.versionedAnswers.v1, id, scope);
  }

  public isCollection(id: string): boolean {
    return this.answerResolverV1.isCollection(id);
  }

  public getRepeatedAnswers<T extends string>(
    collectionId: string,
    ids: T[],
    scope: InstanceScope,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    return this.answerResolverV1.getRepeatedAnswers(this.versionedAnswers.v1, collectionId, ids, scope);
  }

  public getRepetitionCount(collectionId: string, scope: InstanceScope): number | undefined {
    return this.answerResolverV1.getRepetitionCount(this.versionedAnswers.v1, collectionId, scope);
  }

  public setAnswer(value: unknown, id: string, scope?: InstanceScope, answersForPath?: Answers): void {
    this.answerResolverV1.setAnswer(value, this.versionedAnswers.v1, id, scope, answersForPath);
    this.answerResolverV2.setAnswer(value, this.versionedAnswers.v2, id, scope, answersForPath);
  }

  public unsetAnswer(id: string, scope?: InstanceScope, answersForPath?: Answers): [boolean, VersionedAnswers] {
    this.answerResolverV2.unsetAnswer(this.versionedAnswers.v2, id, scope, answersForPath);

    const hasRemovedSomething = this.answerResolverV1.unsetAnswer(this.versionedAnswers.v1, id, scope, answersForPath);

    return [hasRemovedSomething, this.versionedAnswers];
  }

  public unsetAnswerSelectOptionId(id: string, optionId: string, scope?: InstanceScope): boolean {
    this.answerResolverV2.unsetAnswerSelectOptionId(this.versionedAnswers.v2, id, optionId, scope);

    return this.answerResolverV1.unsetAnswerSelectOptionId(this.versionedAnswers.v1, id, optionId, scope);
  }

  public withCollectionIdentifier(
    identifiers: InstanceScope,
    collectionId: string,
    collectionIdentifier: InstanceIndex,
  ): InstanceScope {
    return this.answerResolverV1.withCollectionIdentifier(identifiers, collectionId, collectionIdentifier);
  }

  public removeUndefinedAnswersFromCollection(id: string, scope?: InstanceScope): [boolean, VersionedAnswers] {
    this.answerResolverV2.removeUndefinedAnswersFromCollection(id, this.versionedAnswers.v2, scope);

    const hasRemovedSomething = this.answerResolverV1.removeUndefinedAnswersFromCollection(
      id,
      this.versionedAnswers.v1,
      scope,
    );

    return [hasRemovedSomething, this.versionedAnswers];
  }
}
