import _ from 'lodash';

import { RenderingSection, RenderingSectionGroup } from './RenderingQuestionnaire';

export type ActiveSectionId = {
  sectionGroupId: string;
  sectionGroupRepetitionIndex?: number;
  sectionId: string;
};

export function getNextSection(
  sectionGroup?: RenderingSectionGroup,
  section?: RenderingSection,
): RenderingSection | undefined {
  if (!sectionGroup || !section) {
    return undefined;
  }

  const sectionIndex = _.indexOf(sectionGroup.sections, section);
  return sectionGroup.sections[sectionIndex + 1];
}

export function getPreviousSection(
  sectionGroup?: RenderingSectionGroup,
  section?: RenderingSection,
): RenderingSection | undefined {
  if (!sectionGroup || !section) {
    return undefined;
  }

  const sectionIndex = _.indexOf(sectionGroup.sections, section);
  return sectionGroup.sections[sectionIndex - 1];
}
